import { Link } from "react-router-dom"

const Withdraw = () =>{
    return(
        <>
        <div className="wrap">
                <div className=" content container-fluid"> 
                    <div className="row g-4 justify-content-center " >
                        <div className="col-lg-12" >
                            <div className="block-head block-head-sm">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="block-head-content">
                                        <h3 className=" text-white mb-0"> 
                                            <i className="ri-corner-left-up-fill me-3"></i>
                                        Withdraw
                                        </h3> 
                                    </div>
                                    
                                    <div className="block-head-content d-none d-xl-block">
                                        <div className="container-fluid py-2">
                                            <nav aria-label="breadcrumb">
                                                <ol className="breadcrumb py-2 px-0">
                                                <li className="breadcrumb-item"><Link to="/account/dashboard">Home</Link></li> 
                                                    <li className="breadcrumb-item active" aria-current="page">Withdraw  </li>
                                                </ol>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* <!-- user profile card --> */}
                        <div className="col-xxl-6 col-lg-8 m-auto">
                            <div className="card  h-100"> 
                                <div className="card-body ">
                                    <div className="balance-libra card-success mb-4 mt-0">
                                        <div className="token-img-bg_right" id="lc-data"> Available   Balance : <b> $0  </b> </div> 
                                    </div>
                                    <form>
                                        <div className="mb-3">
                                            <label className="form-label" for="exampleInputMember">Currency Type</label>
                                            <select className="form-control form-select">
                                                <option value="USDT">USDT</option> 
                                            </select>
                                        </div> 
                                        <div className="mb-3">
                                            <label className="form-label">Enter OTP</label>
                                            <div className="field-otp-box">
                                                <input className="form-control" id="OTP" type="number" value=""/>
                                                <button className="btn btn-sm btn_gradient" type="button">GET OTP</button>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="form-group">
                                                <label className="form-label" for="address">Withdraw Address</label>
                                                <div className="input-group">
                                                    <input className="form-control" id="address" type="text" value=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="form-group">
                                                <label className="form-label" for="exampleInputOTP">Amount($)</label>
                                                <div className="input-group">
                                                    <input className="form-control" id="exampleInputOTP" type="number" value=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <button className="btn btn_gradient w-100 btn-block mt-3 px-5  " type="button">Submit</button>
                                    </form>
                                </div> 
                            </div>
                        </div>

                         
                    </div>
                </div>
            </div>
        </>
    )
}

export default Withdraw