import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import ForgotPassword from "../components/pages/ForgotPassword";
import Signup from "../components/pages/Signup";
import Login from "../components/pages/Login";
import Home from "../components/pages/Home";
import Dashboard from "../components/pages/Dashboard";
import Kyc from "../components/pages/Kyc";
import MyProfile from "../components/pages/MyProfile";
import EditProfile from "../components/pages/EditProfile";
import ChangePassword from "../components/pages/ChangePassword";
import Sidebar from "../components/Sidebar";
import Transactions from "../components/pages/Transactions";
import Withdraw from "../components/pages/Withdraw";
import ComingSoonPage from "../components/pages/CommingSoon";
import HelpCenter from "../components/pages/HelpCenter";
import ReferralList from "../components/pages/ReferralList";
import MyTicketList from "../components/pages/MyTicketList";

const Routing = () => {
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();

  // useEffect(() => {
  //   const handlePopState = (event) => {
  //     if (!token) {
  //       navigate("/login", { replace: true });
  //     }
  //   };

  //   window.addEventListener("popstate", handlePopState);

  //   return () => {
  //     window.removeEventListener("popstate", handlePopState);
  //   };
  // }, [navigate, token]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/forgotPassword" element={<ForgotPassword />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/login" element={!token ? <Login /> : <Navigate to="/account/dashboard" />} />
      <Route exact path="/comingsoon" element={<ComingSoonPage />} />

      {token ? (
        <Route path="/account" element={<Sidebar />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="kyc" element={<Kyc />} />
          <Route path="myProfile" element={<MyProfile />} />
          <Route path="editProfile" element={<EditProfile />} />
          <Route path="changePassword" element={<ChangePassword />} />
          <Route path="transactions" element={<Transactions />} />
          <Route path="withdraw" element={<Withdraw />} />
          <Route path="helpCenter" element={<HelpCenter />} />
          <Route path="referralList" element={<ReferralList />} />
          <Route path="MyTicketList" element={<MyTicketList />} />
        </Route>
      ) : (
        <Route path="*" element={<Navigate to="/login" replace />} />
      )}
    </Routes>
  );
};

const App = () => {
  return (<Router>      <Routing />    </Router>
  );
};

export default App;
