import React, { useEffect, useState } from 'react'
import AuthService from '../../api/services/AuthService'
import { alertErrorMessage, alertSuccessMessage } from '../../config/Utils/CustomAlertMessage'
import DataTableBase from '../../config/DataTable'
import moment from 'moment'
import copy from 'copy-to-clipboard'
import { deployedUrl } from "../../api/apiConfig/Endpoints"

const ReferralList = () => {
    const [reffList, setReffList] = useState("");
    const [data, setData] = useState("");
    const [reffCount, setReffCount] = useState("");
    const [referralLink, setReferralLink] = useState("");
    const [referralPoint, setReferralPoint] = useState("");
    const [totalPaidOut, setTotalPaidOut] = useState("");

    useEffect(() => {
        const handleReffList = async () => {
            await AuthService.getRefferalList().then(async (result) => {
                if (result.success) {
                    try {
                        setData(result?.data?.reverse());
                        setReffList(result?.data);
                    } catch (error) {
                        alertErrorMessage(result?.message);
                    }
                }
                else {
                    alertErrorMessage(result.message);
                }

            });
        }
        handleReffList()
        handleReffCount()
        handleReffCode()

    }, [])


    const handleReffCount = async () => {
        await AuthService.getRefferalCount().then(async (result) => {
            if (result.success) {
                try {
                    setReffCount(result?.data?.refer_count);
                    setReferralPoint(result?.data?.refer_point);
                    setTotalPaidOut(result?.data?.total_paid_out);
                } catch (error) {
                    alertErrorMessage(result?.message);
                }
            }
            else {
                alertErrorMessage(result.message);
            }

        });
    }


    const handleReffCode = async () => {
        await AuthService.getRefferalCode().then(async (result) => {
            if (result.success) {
                try {
                    setReferralLink(result?.data);
                } catch (error) {
                    alertErrorMessage(result?.message);
                }
            }
            else {
                alertErrorMessage(result.message);
            }
        });
    }

    const columns = [ 
        { name: "Date", selector: row => moment(row?.createdAt).format("Do MMMM YYYY"), wrap: true, },
        { name: "Name", selector: row => row?.name },
        { name: "Email Id", wrap: true, selector: row => row?.emailId, },
        { name: "User Code", selector: row => row?.user_code, },
    ];

    const link = `${deployedUrl}signup?reffcode=${referralLink}`;


    return (

        <div class="wrap">
            <div class=" content container-fluid">
                <div className="row g-4 justify-content-center ">
                    <div className="col-lg-12">
                        <div className="block-head block-head-sm">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="block-head-content">
                                    <h3 className=" text-white mb-0">
                                        <i className="ri-user-shared-2-line me-3"></i>
                                        Refer & Earn
                                    </h3>
                                </div>
                                <div className="block-head-content d-none d-xl-block"></div>
                            </div>
                        </div>
                    </div>
                    <div className='col-xxl-8' >
                        <div className='card' >
                            <div className='card-body' >
                                <h4 className='mb-0'  >
                                    Your Referral Link
                                </h4>
                                <p>
                                    Here  are your referral link. Please feel free to use them to attract clients to our platform.
                                </p>
                                <br />
                                <div class="field mt-md-3 ">
                                    <div class="field__wrap  field-otp-box field-otp-box-fill">
                                        <input class="field__input form-control" type="text" name="referral-code" readonly="" value={`${deployedUrl}signup?reffcode=${referralLink === undefined ? "" : referralLink}`} />
                                        <button type="button" className='btn btn-link btn-white' style={{ cursor: "pointer" }} value="copy" onClick={() => { copy(link); alertSuccessMessage("Referral Link Copied") }}>
                                            <i className="ri-file-copy-line"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-xxl-8' >
                        <div className='row g-4' >
                            <div className="col-lg-4 " >
                                <div className="card  " >
                                    <div className="card-body" >
                                        <div class="pg_header">
                                            <div class="hd_innre">
                                                <h4 class="mb-0">Total Referral Count</h4>
                                            </div>
                                        </div>
                                        <div class="card-header no-border p-0">
                                            <h2 class="ref_title mb-0 text-white">{reffCount ? reffCount : 0}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div class="pg_header">
                                            <div class="hd_innre">
                                                <h4 class="mb-0">Total Point Earn </h4>
                                                {/* <button className='btn btn-warning btn-sm no-shadow' disabled > Redeem Pointes </button> */}
                                            </div>
                                        </div>
                                        <div class="card-header no-border p-0">
                                            <h2 class="ref_title mb-0 text-white">{referralPoint ? referralPoint : 0}</h2>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div class="pg_header">
                                            <div class="hd_innre">
                                                <h4 class="mb-0">Total Paid out </h4>
                                                {/* <button className='btn btn-warning btn-sm no-shadow' disabled > Redeem Pointes </button> */}
                                            </div>
                                        </div>
                                        <div class="card-header no-border p-0">
                                            <h2 class="ref_title mb-0 text-white">${totalPaidOut ? totalPaidOut : 0}</h2>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='col-xxl-8' >
                        <h5> Referral List </h5>
                        <div className='card overflow-hidden' >
                            <div className="card-body p-0">
                                <div className="table-responsive" width="100%">
                                    <DataTableBase columns={columns} data={data} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReferralList