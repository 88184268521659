import React from 'react';
import DataTable, { createTheme } from 'react-data-table-component';

createTheme('solarized', {
  text: {
    primary: '#FFFFFF',
    secondary: '#FFFFFF',
  },
  context: {
    text: '#FFFFFF',
  },
  background: {
    default: '#e9ecef0f',
  },
  divider: {
    default: '#FFFFFF',
  },
  action: {
    button: '#FFFFFF',
    hover: '#FFFFFF',
    disabled: '#666666',
  },
  button: {
    default: '#FFFFFF',
    focus: '#FFFFFF',
  },
  sortFocus: {
    default: '#FFFFFF',
  },
  highlightOnHover: {
    default: '#e9ecef0f',
    text: '#FFFFFF',
  },
  striped: {
    default: '#e9ecef0f',
    pagination: {
      button: {
        default: '#FFFFFF',
        hover: '#FFFFFF',
        focus: '#FFFFFF',
        disabled: '#666666',
      },

      text: {
        primary: '#FFFFFF',
        secondary: '#666666',
      },
    }
  },
}, 'dark');

const customStyles = {
  headCells: {
    style: {
      fontWeight: 'bold',
      fontSize: '14px',
      backgroundColor: '#484d44',
      color: '#FFFFFF',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: '8px',
      '&:hover': {
        color: '#FFFFFF',
      },
      '& svg': {
        color: '#FFFFFF',
      },
    },
  },
  cells: {
    style: {
      display: 'flex',
      justifyContent: 'flex-start', // Changed from center to flex-start
      alignItems: 'center',
      '&:hover': {
        color: '#FFFFFF',
      },
    },
  },
};

const DataTableBase = (props) => {
  return (
    <DataTable
      pagination
      direction="auto"
      responsive
      subHeaderAlign="right"
      subHeaderWrap
      striped
      highlightOnHover
      fixedHeader
      theme='solarized'
      customStyles={customStyles}
      {...props}
    />
  );
}

export default DataTableBase;
