import logo from "../config/img/logo.svg"
import { Link, useNavigate } from "react-router-dom"


const Footer = () => {


    
    const navigate = useNavigate()

    const hideMenu = () => {
        var element = document.querySelector('.navbar-collapse');
        element.classList.remove('show');
    }
    return (
        <footer className="footer">
            <div className="container">
                <div className="justify-content-center row">
                    <div className="col-lg-4">
                        <div className="footer-left">
                            <a href="/#" className="navbar-brand">
                                <img className="logoImage" src={logo} alt="" /></a>
                            <p>
                                Welcome to RCB, your trusted partner in the crypto revolution. Our mission is to bring new life to the cryptocurrency market by addressing the issue of dead or inactive cryptocurrencies.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="footer-right">
                            <ul className="footer-menu">
                                <li><a href="#about" onClick={() => { hideMenu(); navigate("/") }}>About Us</a></li>
                                <li><a href="#benefits" onClick={() => { hideMenu(); navigate("/") }}>How it works</a></li>
                                <li><a href="#whycus" onClick={() => { hideMenu(); navigate("/") }}>Why choose us</a></li>
                                <li><a href="#community" onClick={() => { hideMenu(); navigate("/") }}>Community</a></li>
                            </ul>
                            <div className="footer-trams">
                                <p>© 2024. All rights reserved by  Rebirth Crypto Burn</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer