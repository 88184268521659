import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AuthService from "../../api/services/AuthService";
import LoaderHelper from "../../config/Utils/Loading/LoaderHelper";
import { alertErrorMessage } from "../../config/Utils/CustomAlertMessage";
import moment from "moment";
import { Endpoints } from "../../api/apiConfig/Endpoints";
import DataTableBase from "../../config/DataTable";


const MyTicketList = () => {
  const [myTicketList, setMyTicketList] = useState([]);
  const [data, setData] = useState("");


  useEffect(() => {
    const handleMyTicket = async () => {
      LoaderHelper.loaderStatus(true);
      try {
        const result = await AuthService.getTicketList();
        if (result.success) {
          LoaderHelper.loaderStatus(false);
          // alertSuccessMessage(result.message);
          setMyTicketList(result.data.reverse());
          setData(result.data.reverse());
        } else {
          alertErrorMessage(result.message);
        }
      } catch (error) {
        alertErrorMessage(error.message);
      } finally {
        LoaderHelper.loaderStatus(false);
      }
    };
    handleMyTicket();
  }, [])

  const imageFormatter = (row) => {
    return (
      <img style={{ width: "40%", height: "auto" }} className="table-img" src={Endpoints?.baseurl + row?.images[0]} alt="img" />
    );
  };

  const columns = [
    { name: "Date", selector: row => moment(row?.createdAt).format("Do MMMM YYYY"), wrap: true, grow: .5 },
    { name: "Name", selector: row => row?.name, grow: .5, wrap: true, },
    { name: <div style={{ whiteSpace: 'revert' }}>Ticket Number</div>, selector: row => row?.ticket_number, grow: .5, wrap: true, },
    { name: <div style={{ whiteSpace: 'revert' }}>Mobile Number</div>, selector: row => row?.mobile_number, grow: .5, wrap: true },
    { name: "Email Id", wrap: true, selector: row => row?.email, grow: .5 },
    { name: <div style={{ whiteSpace: 'revert' }}>Title</div>, wrap: true, selector: row => row?.title, grow: .5 },
    { name: "Snap Shot", selector: imageFormatter, grow: .5, wrap: true, },
    { name: "Admin Reply", selector: row => row?.admin_reply, grow: .5, wrap: true, },
    { name: "Description", selector: row => row?.description, grow: .5, wrap: true, },
    { name: "Status", selector: row => row?.status, grow: .5, wrap: true, },
    // { name: "Action", selector: linkFollow },
  ];


  return (
    <>
      <div class="wrap">
        <div class=" content container-fluid">


          <div className="d-flex justify-content-between align-items-center p-4">
            <div className="d-flex align-itgems-center" style={{ fontSize: '30px' }}>
              <Link to="/account/helpCenter" className=" text-warning  me-3 ps-0">
                <small> <i class="ri-arrow-left-line  "></i></small>
              </Link>  <strong>Help Center</strong>
            </div>

          </div>
          {/* <section className="inner-page-banner">
            <div className="container">
              <div className="inner text-center">
                <h1 className="title text-gradient">
                  My Ticket List
                </h1>
                <nav className="mt-4">
                  <ol className="breadcrumb justify-content-center">
                    <li className="breadcrumb-item">
                      <Link to="/account/helpCenter">Rise Your Ticket</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      My Ticket List
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </section> */}
          <section className="">
            <div className="container">
              <div className="tab-content custom-tab-content p-0">
                <div className="tab-pane fade show container active form-field-wrapper table_scroll p-0 switch_btn  border-dashed border-gray-300 bg-lighten card-rounded" id="tt_history">
                  <DataTableBase columns={columns} data={data} />
                </div>
              </div>
            </div>
          </section>
        </div>

      </div>
    </>
  );
};

export default MyTicketList;
