import React from "react";

const ComingSoonPage = () => {

    return (
        <div class="wrap">
            <div class=" content container-fluid">
                <div className="soon_img" >
                    <img alt="" src="/assets/img/coming_dark.svg" className="img-fluid" />
                </div>
            </div>
        </div>
        // <section className="soon_sec">
        //     <div className="container">
        //         <div className="soon_img" >
        //             <img alt="" src="/assets/img/coming_dark.svg" className="img-fluid" />
        //         </div>
        //     </div>
        // </section>
    );

}

export default ComingSoonPage;