import { Link } from "react-router-dom"
import Footer from "../Footer"
import Header from "../Header"


const Home = () => {


    return (
        <>
            <Header />
            <div className="hero">
                <p className="siteC"> REBIRTH CRYPTO BURN </p>
                <figure className="heroTopShap"><img src="assets/img/heroTop-shap.svg" alt="" /></figure>
                <ul className="social-links">
                    <li><a className="joc_social" title="WhatsApp" target="_blank" rel="noopener noreferrer" href={`https://wa.me/9521852503`}>
                        <i className="ri-whatsapp-line ri-xl"></i>
                    </a></li>
                    <li><a className="joc_social" itle="Telegram" target="_blank" rel="noopener noreferrer" href="https://t.me/RCB_THE_SAVIOUR">
                        <i className="ri-telegram-line ri-xl"></i>
                    </a></li>
                    <li><a href="twitter" target="_blank"><i className="ri-twitter-x-line"></i></a></li>
                    <li><a href="instagram" target="_blank"><i className="ri-instagram-line"></i></a></li>
                    <li><a href="facebook" target="_blank"><i className="ri-facebook-line"></i></a></li>
                </ul>
                <div className="container">
                    <div className="align-items-start row">
                        <div className="col-md-7 col-lg-6">
                            <div className="hero-content"><span className="subTitle">WELCOME TO RCB</span>
                                <h1>Start crypto
                                    <br />  burning IN A</h1>
                                <h2 className="newWay">New Way</h2>
                                <div className="hero_action" >
                                    <Link to="/signup" className="inlineBtn inlineBtn_fill">
                                        Get Started
                                        <img src="/assets/img/arrow_dark.svg" className="img-fluid" width="31" alt="" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className=" col-md-5 col-lg-6">
                            <div className="hero-right">
                                <div className="coin-image">
                                    {/* <!-- <img src="assets/img/main-img.svg" alt="Banner background" /> --> */}
                                    <div className="circle">

                                    </div>
                                    <img src="/assets/img/coin.svg" className="img-fluid" alt="" />
                                    <svg>
                                        <filter id="wavy">
                                            <feTurbulence x="0" y="0" baseFrequency="0.009" numOctaves="5" speed="2">
                                                <animate attributeName="baseFrequency" dur="60s" values="0.02; 0.005; 0.02" repeatCount="indefinite" />
                                            </feTurbulence>
                                            <feDisplacementMap in="SourceGraphic" scale="30"></feDisplacementMap>
                                        </filter>
                                    </svg>
                                </div>
                                <p>
                                    Crypto burning permanently removes coins from circulation,
                                    aiming to increase scarcity and potentially boost value.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="about" id="about">
                {/* <p className="siteC"> REBIRTH CRYPTO BURN</p>
                <div className="container">
                    <div className="row">
                        <div className="order-md-1 order-2 col-md-5">
                            <div className="about-left"> 
                                <figure className="aboutImg"><img src="/assets/img/about-img.svg" alt="" /></figure>
                            </div>
                        </div>
                        <div className="order-md-2 order-1 col-md-7">
                            <div className="about-right">
                                <h2> About Us</h2>
                                <div className="about-right-wrap about-margin-top" > 

                                    <p>Welcome to RCB, your trusted partner in the crypto revolution. Our mission is to bring new life to the cryptocurrency market by addressing the issue of dead or inactive cryptocurrencies. We are dedicated to creating value for our users through our innovative Crypto Burning Program. </p>
                                    <p> <strong>Our Mission : </strong>
                                        In the dynamic world of cryptocurrencies, numerous tokens and coins lose their relevance and become dead assets. We believe in transforming these dormant assets into valuable opportunities. Our mission is to burn these dead cryptocurrencies and return the value back to our users, ensuring a healthier and more vibrant crypto ecosystem. </p>
                                    <p> <strong> Our Vision : </strong>

                                        We envision a thriving and sustainable cryptocurrency market where every asset has value and purpose. Through our Crypto Burning Program, we aim to rejuvenate the market, eliminate dead assets, and provide tangible benefits to our community. </p>
                                </div>

                            </div>
                        </div>
 

                    </div>
                </div> */}

                <section class="os_sec ">
                    <div class="container">
                        <div class="row justify-content-center g-3 mobile_row_scroll ">
                            <div class="col-xxl-4 col-xl-4 col-lg-4 ">
                                <div class="card fe_body h-100">
                                    <div class="card-body"><img src="/assets/img/about-img.svg" width="100" class="img-fluid" />
                                        <h5> About Us  </h5>
                                        <p class="mb-0">Welcome to RCB, your trusted partner in the crypto revolution. Our
                                            mission is to bring new life to the cryptocurrency market by addressing the issue of
                                            dead or inactive cryptocurrencies. We are dedicated to creating value for our users
                                            through our innovative Crypto Burning Program.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-4 col-xl-4 col-lg-4 ">
                                <div class="card fe_body h-100">
                                    <div class="card-body"><img src="/assets/img/our_mission.png" width="100" class="img-fluid" />
                                        <h5> Our Mission    </h5>
                                        <p class="mb-0">In the dynamic world of cryptocurrencies, numerous
                                            tokens and coins lose their relevance and become dead assets. We believe in
                                            transforming these dormant assets into valuable opportunities. Our
                                            mission is to burn these dead cryptocurrencies and return the value back to
                                            our users, ensuring a healthier and more vibrant crypto ecosystem.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-4 col-xl-4 col-lg-4 ">
                                <div class="card fe_body h-100">
                                    <div class="card-body"><img src="/assets/img/our_vision.png" width="100" class="img-fluid" />
                                        <h5>  Our Vision   </h5>
                                        <p class="mb-0"> We envision a thriving and sustainable cryptocurrency
                                            market where every asset has value and purpose. Through our
                                            Crypto Burning Program, we aim to rejuvenate the market,
                                            eliminate dead assets, and provide tangible benefits to our community.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>


            <section className="why_us" id="benefits">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-7">
                            <div className="section-title text-center">
                                <h2> <span>How it works</span> </h2>
                                <p className="d-none d-lg-block">Begin your journey with RCB by registering on our platform. It's quick and easy! </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-10">
                            <div className="wl_row" >
                                <div className="wallet-block text-center hiw_card  ">
                                    <div className="thumb m-auto"><img src="/assets/img/login-board.webp" alt="nft wallet" /></div>
                                    <div className="st-content" >
                                        <span>01</span>
                                        <h4 className="title text-gradient">Login &amp; Register</h4>
                                        <p> Visit our website and click on the "Register" button.
                                            Fill in the required details such as your name, email address, and password.
                                            Verify your email address by clicking on the link sent to your inbox.
                                            Log in to your new account and get ready to start your crypto burning experience.</p>
                                    </div>
                                </div>
                                <div className="wallet-block text-center hiw_card  ">
                                    <div className="thumb m-auto"><img src="/assets/img/code-approved.webp" alt="nft wallet" /></div>
                                    <div className="st-content" >
                                        <span>02</span>
                                        <h4 className="title text-gradient">Complete KYC Verification</h4>
                                        <p>To ensure the security and integrity of our platform, all users must complete the Know Your Customer (KYC) verification process.
                                            Log in to your account and navigate to the "KYC Verification" section.Provide the necessary documents, such as a government-issued ID and proof of address.
                                            Submit the documents for review.Our team will verify your information within 24-48 hours. Once approved, you will receive a confirmation email, and your account will be fully activated.</p>

                                    </div>
                                </div>
                                <div className="wallet-block text-center hiw_card  ">
                                    <div className="thumb m-auto"><img src="/assets/img/burning.png" alt="nft wallet" /></div>
                                    <div className="st-content" >
                                        <span>03</span>
                                        <h4 className="title text-gradient"> Participate in Crypto Burning </h4>
                                        <p>Now that your account is verified, you can participate in our Crypto Burning Program.Navigate to the "Burning Program" section of the platform.
                                            You can just select the dead cryptocurrencies you wish to burn from the available list.You can choose the amount you want to burn and start the burning.
                                            Once the burning is complete, you will receive returns based on the value of the burned assets.</p>
                                    </div>
                                </div>
                                <div className="wallet-block text-center hiw_card">
                                    <div className="thumb m-auto"><img src="/assets/img/profit-growth.webp" alt="nft wallet" /></div>
                                    <div className="st-content" >
                                        <span>04</span>
                                        <h4 className="title text-gradient"> Receive Your Returns </h4>
                                        <p>After successfully burning the dead cryptocurrencies, you will receive your returns.Your returns will be credited to your account balance.
                                            You can withdraw your returns to your linked bank account or cryptocurrency wallet.Alternatively, you can reinvest your returns in other opportunities available on our platform.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <section className="benefitsArea" id="whycus">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="section-title text-md-center">
                                <h2>why choose <br /> <span>REBIRTH CRYPTO burn</span> </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="benefit-content-wrapper">
                    <div className="container">
                        <div className="row g-0 benefits-wrapper ">
                            <div className="col-lg-12">
                                <div className="bnsi_it_row">
                                    <div className="benefits-single-item">
                                        <figure><img src="/assets/img/bf1.svg" alt="" /></figure>
                                        <div className="single-c-item ">
                                            <h4> Innovative Approach</h4>
                                            <div>At Rebirth Crypto Burn, we utilize cutting-edge technology to identify and burn dead cryptocurrencies, revitalizing the market and providing tangible returns to our users. Our innovative approach ensures that your investments are part of a forward-thinking and impactful initiative.</div>
                                        </div>
                                    </div>
                                    <div className="benefits-single-item c_reverce">
                                        <figure><img src="/assets/img/bf2.svg" alt="" /></figure>
                                        <div className="single-c-item ">
                                            <h4>User-Centric Platform</h4>
                                            <div>Our platform is designed with you in mind. We prioritize user experience, making it easy to register, complete KYC verification, and participate in our Crypto Burning Program. Transparency, security, and ease of use are at the core of our services.</div>
                                        </div>
                                    </div>
                                    <div className="benefits-single-item ">
                                        <figure><img src="/assets/img/bf3.svg" alt="" /></figure>
                                        <div className="single-c-item ">
                                            <h4>Experienced Team</h4>
                                            <div>Our team comprises industry veterans and blockchain experts who are passionate about cryptocurrency and its potential. Their expertise and dedication ensure that our processes are efficient, secure, and beneficial for our users.</div>
                                        </div>
                                    </div>
                                    <div className="benefits-single-item c_reverce">
                                        <figure><img src="/assets/img/bf4.svg" alt="" /></figure>
                                        <div className="single-c-item ">
                                            <h4>Proven Track Record</h4>
                                            <div>With a history of successful crypto burns and satisfied users, Rebirth Crypto Burn has established itself as a trusted player in the market. Our proven track record speaks to our commitment to excellence and user satisfaction.</div>
                                        </div>
                                    </div>
                                    <div className="benefits-single-item ">
                                        <figure><img src="/assets/img/bf5.svg" alt="" /></figure>
                                        <div className="single-c-item ">
                                            <h4>Community Engagement</h4>
                                            <div>We believe in the power of community. By engaging with our users, we continuously improve and innovate based on your feedback. Your participation and insights help us create a more robust and rewarding platform for everyone.</div>
                                        </div>
                                    </div>
                                    <div className="benefits-single-item c_reverce">
                                        <figure><img src="/assets/img/bf6.svg" alt="" /></figure>
                                        <div className="single-c-item ">
                                            <h4>Secure and Compliant</h4>
                                            <div>Security is our top priority. We implement rigorous security measures to protect your data and investments. Additionally, we adhere to all relevant regulations and industry standards, ensuring a compliant and trustworthy service.</div>
                                        </div>
                                    </div>
                                    <div className="benefits-single-item ">
                                        <figure><img src="/assets/img/bf7.svg" alt="" /></figure>
                                        <div className="single-c-item ">
                                            <h4>Attractive Returns</h4>
                                            <div>Our unique Crypto Burning Program not only helps clean up the market but also provides attractive returns to our users. By burning dead cryptocurrencies, we create value and offer you the opportunity to earn from this process.</div>
                                        </div>
                                    </div>
                                    <div className="benefits-single-item c_reverce">
                                        <figure><img src="/assets/img/bf8.svg" alt="" /></figure>
                                        <div className="single-c-item ">
                                            <h4>Future-Focused Vision</h4>
                                            <div>We are committed to creating a sustainable and vibrant cryptocurrency market. Our long-term vision includes continuous innovation and expansion, ensuring that we remain at the forefront of the crypto revolution.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="community" className="partner_community pc_bg pt-5">
                <div className="community_sec">
                    <div className="container">
                        <div className="card">
                            <div className="card-body">
                                <div className="row align-items-center">
                                    <div className="col-lg-7">
                                        <div className="section-title mb-0 text-start pb-0 no-border">
                                            <h2 className="text-gradient">Join Our Community </h2>
                                            <p className=" mb-0">Crypto burning permanently removes coins from circulation, aiming to increase scarcity and potentially boost value.</p>
                                            <div className="d-flex  joc_social_row ">
                                                <a className="joc_social" itle="Telegram" target="_blank" rel="noopener noreferrer" href="https://t.me/RCB_THE_SAVIOUR">
                                                    <i className="ri-telegram-line ri-xl"></i>
                                                </a>
                                                <a
                                                    className="joc_social"
                                                    title="WhatsApp"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={`https://wa.me/9521852503`}
                                                >
                                                    <i className="ri-whatsapp-line ri-xl"></i>
                                                </a>
                                                <a className="joc_social" title="Facebook" target="_blank" href="/#"><i className="ri-facebook-line  ri-xl"></i></a>
                                                <a className="joc_social" title="Twitter" target="_blank" href="/#"><i className="ri-twitter-line  ri-xl"></i></a>
                                                <a className="joc_social" title="Instagram" target="_blank" href="/#"><i className="ri-instagram-line  ri-xl"></i></a>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5"><img src="/assets/img/community_img.svg" alt="" className="img-fluid joc_img" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>)
}

export default Home