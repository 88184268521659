import React, { useEffect, useState } from "react";
import arrowDark from "../../config/img/arrow_dark.svg";
import LoaderHelper from "../../config/Utils/Loading/LoaderHelper";
import AuthService from "../../api/services/AuthService";
import { alertErrorMessage } from "../../config/Utils/CustomAlertMessage";
import { Link, useNavigate } from "react-router-dom";
import { Endpoints } from "../../api/apiConfig/Endpoints";
import ReactPaginate from "react-paginate";

const Dashboard = () => {
  const ITEMS_PER_PAGE_CRYPTO = 4;
  const ITEMS_PER_PAGE_PACKAGE = 6;
  const Navigate = useNavigate();

  const [data, setData] = useState([]);
  const [profileData, setProfileData] = useState("");
  const [packageData, setPackageData] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [currentPageCrypto, setCurrentPageCrypto] = useState(0);
  const [currentItemCrypto, setCurrentItemCrypto] = useState([]);
  const [currentPagePackage, setCurrentPagePackage] = useState(0);
  const [currentItemPackage, setCurrentItemPackage] = useState([]);

  const nextStep = () => {
    var element = document.querySelector(".step_1");
    element.classList.add("d-none");
    var element1 = document.querySelector(".step_2");
    element1.classList.remove("d-none");
  };
  const nextStep1 = () => {
    var element = document.querySelector(".step_2");
    element.classList.add("d-none");
    var element1 = document.querySelector(".step_3");
    element1.classList.remove("d-none");
  };

  const backStep = () => {
    var element = document.querySelector(".step_2");
    element.classList.add("d-none");
    var element1 = document.querySelector(".step_1");
    element1.classList.remove("d-none");
  };
  const backStep1 = () => {
    var element = document.querySelector(".step_3");
    element.classList.add("d-none");
    var element1 = document.querySelector(".step_2");
    element1.classList.remove("d-none");
  };

  const nextStep2 = () => {
    var element = document.querySelector(".step_3");
    element.classList.add("d-none");
    var element1 = document.querySelector(".step_4");
    element1.classList.remove("d-none");
  };

  const backStep2 = () => {
    var element = document.querySelector(".step_4");
    element.classList.add("d-none");
    var element1 = document.querySelector(".step_3");
    element1.classList.remove("d-none");
  };

  const nextStep3 = () => {
    var element = document.querySelector(".step_4");
    element.classList.add("d-none");
    var element1 = document.querySelector(".step_5");
    element1.classList.remove("d-none");
  };

  const backStep3 = () => {
    var element = document.querySelector(".step_5");
    element.classList.add("d-none");
    var element1 = document.querySelector(".step_4");
    element1.classList.remove("d-none");
  };

  const nextStep4 = () => {
    var element = document.querySelector(".step_5");
    element.classList.add("d-none");
    var element1 = document.querySelector(".step_6");
    element1.classList.remove("d-none");
  };


  useEffect(() => {
    handleData();
    handleProfileData();
    handlePackageData();
  }, []);

  const handleData = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getCoinList().then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result.data) {
        try {
          setData(result.data.reverse());
          Navigate("/account/dashboard");
        } catch (error) {
          alertErrorMessage("No data found");
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage("No data found");
      }
    });
  };

  const handlePackageData = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getPackageList().then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result.data) {
        try {
          LoaderHelper.loaderStatus(false);
          setPackageData(result?.data?.reverse());
          Navigate("/account/dashboard");
        } catch (error) {
          LoaderHelper.loaderStatus(false);
          alertErrorMessage("No data found");
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage("No data found");
      }
    });
  };

  const handleProfileData = async () => {
    const result = await AuthService.getProfileData();
    if (result.success) {
      try {
        setProfileData(result?.data);
      } catch (error) {
        alertErrorMessage(result?.message);
      }
    } else {
      alertErrorMessage(result.message);
    }
  };

  const handleClick1 = async () => {
    await handlePackageData();
    toggleActive2();
    nextStep1();
  };
  const handleClick = async () => {
    await handlePackageData();
    toggleActive2();
    nextStep();
  };

  const toggleActive = (index) => {
    const globalIndex = currentPageCrypto * ITEMS_PER_PAGE_CRYPTO + index;
    const updatedData = data.map((item, idx) => {
      if (idx === globalIndex) {
        return { ...item, active: !item.active };
      } else {
        return { ...item, active: false };
      }
    });
    setData(updatedData);
    setCurrentItemCrypto(updatedData.slice(currentPageCrypto * ITEMS_PER_PAGE_CRYPTO, currentPageCrypto * ITEMS_PER_PAGE_CRYPTO + ITEMS_PER_PAGE_CRYPTO));
  };

  const toggleActive2 = (index) => {
    const globalIndex = currentPagePackage * ITEMS_PER_PAGE_PACKAGE + index;
    const updatedData = packageData?.map((item, idx) => {
      if (idx === globalIndex) {
        return { ...item, active: !item.active };
      } else {
        return { ...item, active: false };
      }
    });
    setPackageData(updatedData);
    setCurrentItemPackage(updatedData?.slice(currentPagePackage * ITEMS_PER_PAGE_PACKAGE, currentPagePackage * ITEMS_PER_PAGE_PACKAGE + ITEMS_PER_PAGE_PACKAGE));
  };



  const handlePageClickCoin = ({ selected }) => {
    const newOffset = selected * ITEMS_PER_PAGE_CRYPTO;
    setCurrentItemCrypto(data?.slice(newOffset, newOffset + ITEMS_PER_PAGE_CRYPTO));
    setCurrentPageCrypto(selected);
  };



  const handlePageClickPckg = ({ selected }) => {
    const newOffset = selected * ITEMS_PER_PAGE_PACKAGE;
    setCurrentItemPackage(packageData?.slice(newOffset, newOffset + ITEMS_PER_PAGE_PACKAGE));
    setCurrentPagePackage(selected);
  }




  return (
    <>
      <div className="wrap">
        <div className=" content container-fluid">
          <div className="row g-4 justify-content-center ">
            <div className="col-lg-12">
              <div className="block-head block-head-sm">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="block-head-content">
                    <h3 className=" text-white mb-0">
                      <i className="ri-dashboard-line me-3"></i>
                      Dashboard
                    </h3>
                  </div>
                  <div className="block-head-content d-none d-xl-block"></div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="card dh_card">
                <div className="card-body">
                  <div className="dh_head">
                    <div>
                      <h3> {profileData?.fullName?.toUpperCase()}</h3>
                      <p>UID : #RCB{profileData?.uin}</p>
                    </div>
                    {/* <div className="phase_countdown">
                      <p>
                        Ongoing Phase: <b>1</b>
                      </p>
                      <div className="time_left">
                        <span>
                          29
                          <small>Days</small>
                        </span>
                        :
                        <span>
                          23
                          <small>Hrs</small>
                        </span>
                        :
                        <span>
                          59
                          <small>Min</small>
                        </span>
                        :
                        <span>
                          59
                          <small>Sec</small>
                        </span>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-9  col-xxl-7 ">
              {/* <!-- step 1 --> */}
              <div className="step_1">
                <div className="card-body">
                  <h4>Start Crypto Burning with RCB!</h4>
                  <p>
                    RCB is a crypto burning program designed to help users eliminate their worthless cryptocurrencies in a secure and efficient manner. Through this program, you can burn digital assets that have lost all value, effectively cleaning up your wallet and freeing it from unnecessary digital clutter. The RCB dashboard features a sleek design, allowing you to easily track your burning activities, review transaction records, and manage your assets within a safe environment. RCB provides a seamless platform where you can effortlessly dispose of your worthless cryptocurrencies, ensuring a smoother journey forward in your digital asset management.
                  </p>
                  <div className="hero_action">
                    <a
                      href={`http://103.175.163.162:5036/signup?userId=${profileData?._id}`}
                      target="_blank"
                      className="inlineBtn inlineBtn_fill"
                      rel="noreferrer"
                    >
                      SIGN UP
                      <img src={arrowDark} className="img-fluid" width="31" alt="" />
                    </a>

                  </div>

                </div>
              </div>
              {/* <!-- step 2 --> */}
              <div className="step_2 d-none">
                <div className="card">
                  <div className="card-body">
                    <p className="h5 fw-normal">Select Your Crypto</p>
                    {currentItemCrypto?.map((item, index) => (
                      <div className="p_list">
                        <div className={`p_item ${item.active ? "active" : ""}`} key={index} onClick={() => toggleActive(index)}>
                          <img src={Endpoints?.baseurl + item?.coin_logo} alt="img" />
                          <span>
                            {item?.coin_name}
                            <i className={item?.active ? "ri-checkbox-circle-fill" : "ri-circle-line"}></i>
                          </span>
                        </div>
                      </div>
                    ))}

                    <div className="hero_action row gx-2">
                      <div className="col">
                        <div className="btn btn-xl boxedBtn_border btn-block w-100" onClick={() => backStep()}>
                          BACK
                        </div>
                      </div>
                      <div className="col">
                        <button type="button" className="btn btn-xl btn_gradient btn-block w-100" disabled={!data.some((item) => item.active)} onClick={() => handleClick1()}>
                          Select Package
                        </button>
                      </div>
                    </div>
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      pageCount={Math.ceil(data?.length / ITEMS_PER_PAGE_CRYPTO)}
                      pageClassName="page-item"
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageClickCoin}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
              {/* <!-- step 3 --> */}
              <div className="step_3 d-none">
                <div className="card">
                  <div className="card-body">
                    <p className="h5 fw-normal">Select Your Package</p>
                    <div className="row">
                      {currentItemPackage.map((item, index) => (
                        <div className="col-12 col-md-4 mb-4" key={index}>
                          <div className="plan_list mb-4" >
                            <div className="row-2 g-2">
                              <div
                                className="col-12"
                                onClick={() => toggleActive2(index)}
                              >
                                <span
                                  className="dd_link bg_light_gyay1"
                                  style={{
                                    background: item.active
                                      ? "linear-gradient(90deg, rgba(254, 194, 10, 1) 48%, rgba(247, 142, 27, 1) 93%)"
                                      : "",
                                  }}
                                >
                                  <p
                                    className="d-flex justify-content-between align-items-center"
                                    style={{ color: item.active ? "black" : "" }}
                                  >
                                    {item?.package_name}
                                    <i
                                      className={
                                        item.active
                                          ? "ri-checkbox-circle-fill"
                                          : "ri-circle-line"
                                      }
                                    ></i>
                                  </p>
                                  <h4 style={{ color: item.active ? "black" : "" }}>
                                    {item?.package_price}
                                  </h4>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                      ))}
                    </div>
                    <div className="hero_action row gx-2">
                      <div className="col">
                        <div
                          className="btn btn-xl boxedBtn_border btn-block w-100"
                          onClick={() => backStep1()}
                        >
                          BACK
                        </div>
                      </div>
                      <div className="col">
                        <button
                          type="button"
                          className="btn btn-xl btn_gradient btn-block w-100"
                          disabled={!packageData.some((item) => item.active)}
                          onClick={() => nextStep2()}
                        >
                          PROCEED
                        </button>
                      </div>
                    </div>
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      pageCount={Math.ceil(packageData.length / ITEMS_PER_PAGE_PACKAGE)}
                      pageClassName="page-item"
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClickPckg}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
              {/* <!-- step 4 --> */}
              <div className="step_4 d-none">
                <div className="card">
                  <div className="card-body tc_card">
                    <h5 className="h5">
                      Terms & Conditions
                      <br />
                      <small>Last Edit: 01/04/2024 </small>
                    </h5>
                    <div className="tc_conditions plan_list">
                      <span>

                        <i className="ri-check-line me-2"></i>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </span>
                      <span>

                        <i className="ri-check-line me-2"></i>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </span>
                      <span>

                        <i className="ri-check-line me-2"></i>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </span>
                      <span>

                        <i className="ri-check-line me-2"></i>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </span>
                    </div>

                    <div className="col-md-12">
                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexCheckDefault"
                          value={isChecked}
                          onClick={() =>
                            setIsChecked((isChecked) => !isChecked)
                          }
                        />
                        {/* <p className="d-block">  */}
                        I Agree To The Terms And Conditions And I Read The
                        Privacy Policy
                        {/* </p> */}
                        <br />
                        {!isChecked || showMessage ? (
                          <div style={{ color: "red", fontWeight: "bold" }} >
                            Please accept Term of Use by clicking on checkbox
                          </div>
                        ) : (
                          " "
                        )}
                      </div>
                    </div>
                    <div className="hero_action row gx-2">
                      <div className="col">
                        <div
                          className="btn btn-xl boxedBtn_border btn-block w-100"
                          onClick={() => backStep2()}
                        >
                          Back
                        </div>
                      </div>
                      <div className="col">
                        <button
                          type="button"
                          className="btn btn-xl btn_gradient btn-block w-100 "
                          disabled={!isChecked}
                          onClick={() => nextStep3()}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- step 5 --> */}
              <div className="step_5 d-none">
                <div className="card">
                  <div className="card-body tc_card">
                    <div className="d-flex align-items-center link_head gap-4">
                      <img src="assets/img/octa.svg" height="20" alt="" />
                      <h5 className="h5">
                        Link your Octafx Account!
                        <br />
                        <small>
                          Enter Details to Link your OCTAFX Account with RCB
                        </small>
                      </h5>
                    </div>
                    <div className="tc_conditions plan_list">
                      <div className="form-group mb-4">
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <label> Octafx Name </label>
                          {/* <!-- <i className="ri-question-line cursor-pointer" title="How to find OCTAFX Name" data-bs-toggle="modal" data-bs-target="#namehelopmodal" ></i> --> */}
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Name"
                        />
                      </div>
                      <div className="form-group mb-4">
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <label> Octafx Email Id </label>
                          {/* <!-- <i className="ri-question-line cursor-pointer" title="How to find OCTAFX Name" data-bs-toggle="modal" data-bs-target="#namehelopmodal" ></i> --> */}
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Email"
                        />
                      </div>
                      <div className="form-group mb-4">
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <label> Amount (Deposit) </label>
                          {/* <!-- <i className="ri-question-line cursor-pointer" title="How to find OCTAFX Name" data-bs-toggle="modal" data-bs-target="#namehelopmodal" ></i> --> */}
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Amount"
                        />
                      </div>
                      <div className="form-group mb-4 pt-2">
                        <p className="text-white mb-0">
                          Upload Screenshoots for account Verification.
                        </p>
                        <hr className="mt-2 mb-4" />

                        <div className="upload-area">
                          <div className="d-flex align-items-center justify-content-between mb-1">
                            <div className="upload-formate ">
                              <h6 className="title"> Profile Screenshoot </h6>
                              <div className=" text-smallest mb-1">
                                <span>

                                  (Only JPEG, PNG &amp; JPG formats and file
                                  size upto 5MB are supported)
                                </span>
                              </div>
                            </div>
                            <i
                              className="ri-question-line cursor-pointer"
                              title="How to take screenshot"
                              data-bs-toggle="modal"
                              data-bs-target="#namehelopmodal"
                            ></i>
                          </div>
                          <div className="brows-file-wrapper mb-4">
                            <input
                              name="file"
                              id="file2"
                              type="file"
                              className="inputfile"
                              data-multiple-caption="{count} files selected"
                              multiple=""
                              required=""
                            />
                            <label for="file2" title="No File Choosen">
                              <i className="ri-upload-cloud-line"></i>
                              <div className="ms-3">
                                <span className="text-center mb-2">
                                  Choose a File
                                </span>
                                <span className="file-type text-center mt--10">
                                  Drag or choose your file to upload
                                </span>
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className="upload-area">
                          <div className="d-flex align-items-center justify-content-between mb-1">
                            <div className="upload-formate ">
                              <h6 className="title"> Copier Area </h6>
                              <div className=" text-smallest mb-1">
                                <span>

                                  (Only JPEG, PNG &amp; JPG formats and file
                                  size upto 5MB are supported)
                                </span>
                              </div>
                            </div>
                            <i
                              className="ri-question-line cursor-pointer"
                              title="How to take screenshot"
                              data-bs-toggle="modal"
                              data-bs-target="#namehelopmodal"
                            ></i>
                          </div>
                          <div className="brows-file-wrapper mb-4">
                            <input
                              name="file"
                              id="file2"
                              type="file"
                              className="inputfile"
                              data-multiple-caption="{count} files selected"
                              multiple=""
                              required=""
                            />
                            <label for="file2" title="No File Choosen">
                              <i className="ri-upload-cloud-line"></i>
                              <div className="ms-3">
                                <span className="text-center mb-2">
                                  Choose a File
                                </span>
                                <span className="file-type text-center mt--10">
                                  Drag or choose your file to upload
                                </span>
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className="upload-area">
                          <div className="d-flex align-items-center justify-content-between mb-1">
                            <div className="upload-formate ">
                              <h6 className="title"> Copier Area </h6>
                              <div className=" text-smallest mb-1">
                                <span>

                                  (Only JPEG, PNG &amp; JPG formats and file
                                  size upto 5MB are supported)
                                </span>
                              </div>
                            </div>
                            <i
                              className="ri-question-line cursor-pointer"
                              title="How to take screenshot"
                              data-bs-toggle="modal"
                              data-bs-target="#namehelopmodal"
                            ></i>
                          </div>
                          <div className="brows-file-wrapper mb-4">
                            <input
                              name="file"
                              id="file2"
                              type="file"
                              className="inputfile"
                              data-multiple-caption="{count} files selected"
                              multiple=""
                              required=""
                            />
                            <label for="file2" title="No File Choosen">
                              <i className="ri-upload-cloud-line"></i>
                              <div className="ms-3">
                                <span className="text-center mb-2">
                                  Amount
                                </span>
                                <span className="file-type text-center mt--10">
                                  Drag or choose your file to upload
                                </span>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-0">
                        <div className="hero_action row gx-2 d-flex">
                          <div className="col">
                            <div
                              className="btn btn-xl boxedBtn_border btn-block w-100"
                              onClick={() => backStep3()}
                            >
                              Back
                            </div>
                          </div>
                          <div className="col">
                            <input
                              type="submit"
                              className="btn btn-xl btn_gradient btn-block w-100"
                              Value="Verify Acount"
                              // disabled
                              onClick={() => nextStep4()}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tc_conditions plan_list">
                      <div className="row g-3">
                        <div className="col-xxl-12">
                          <p className="text-white mb-0 h5">
                            Don't have Octafx Account? <br />
                            <span> </span>
                            <div className=" text-smallest ">
                              <span>

                                Enter button to create new octafx account.
                              </span>
                            </div>
                          </p>
                        </div>
                        <div className="col-xxl-12">
                          <a
                            href="https://www.octafx.com/"
                            className="btn inlineBtn   btn-block w-100"
                          >

                            Create New OCTAFX Account
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- step 6 --> */}
              <div className="step_6 d-none">
                <div className="card-body text-center py-5">
                  <h4 className="text-white">Thankyou!</h4>
                  <p>
                    Your account is under process, please be patient for
                    complete verification process, we will notify you soon on
                    your registered email.
                  </p>
                </div>
              </div>
              {/* <DataTableBase columns={columns} data={data} /> */}
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default Dashboard;
