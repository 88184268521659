import { ApiCallGet, ApiCallPost, ApiCallPut } from "../apiConfig/ApiCalls";
import { Endpoints } from "../apiConfig/Endpoints";

const AuthService = {

  signup: async (name, email, otp, countryCode, countryName, mobileNumber, password, cPassword, invitation) => {
    const { baseAuth, signup } = Endpoints;
    const url = baseAuth + signup;
    const params = {
      fullName: name,
      email: email,
      country_code: countryCode.trim(),
      country: countryName,
      mobile_number: mobileNumber,
      otp: otp,
      password: password,
      confirmPassword: cPassword,
      referral_code: invitation
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  login: async (email, password) => {
    const { baseAuth, login } = Endpoints;
    const url = baseAuth + login;
    const params = {
      email,
      password
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  kycVerification: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseProfile, kycVerification } = Endpoints;
    const url = baseProfile + kycVerification;

    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    };
    return ApiCallPost(url, formData, headers);
  },
  getProfileData: async () => {
    const token = sessionStorage.getItem("token");
    const { baseProfile, getProfileData } = Endpoints;
    const url = baseProfile + getProfileData;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },
  getCoinList: async () => {
    const token = sessionStorage.getItem("token");
    const { baseProfile, getCoinList } = Endpoints;
    const url = baseProfile + getCoinList;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },
  getPackageList: async () => {
    const token = sessionStorage.getItem("token");
    const { basePackage, getPackageList } = Endpoints;
    const url = basePackage + getPackageList;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },
  getRefferalList: async () => {
    const token = sessionStorage.getItem("token");
    const { baseProfile, getRefferalList } = Endpoints;
    const url = baseProfile + getRefferalList;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },
  getRefferalCount: async () => {
    const token = sessionStorage.getItem("token");
    const { baseProfile, getRefferalCount } = Endpoints;
    const url = baseProfile + getRefferalCount;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },
  getRefferalCode: async () => {
    const token = sessionStorage.getItem("token");
    const { baseProfile, getRefferalCode } = Endpoints;
    const url = baseProfile + getRefferalCode;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  getTicketList: async () => {
    const token = sessionStorage.getItem("token");
    const { baseProfile, getTicketList } = Endpoints;
    const url = baseProfile + getTicketList;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },
  getNotification: async () => {
    const token = sessionStorage.getItem("token");
    const { baseProfile, getNotification } = Endpoints;
    const url = baseProfile + getNotification;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  sendOtp: async (email) => {
    const token = sessionStorage.getItem("token");
    const { baseProfile, sendOtp } = Endpoints;
    const url = baseProfile + sendOtp;
    const params = {
      email,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },
  seenTicket: async (userId) => {
    const token = sessionStorage.getItem("token");
    const { baseProfile, seenTicket } = Endpoints;
    const url = baseProfile + seenTicket;
    const params = {
      user_id: userId,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  getProfile: async () => {
    const token = sessionStorage.getItem("token");
    const { baseProfile, getProfile } = Endpoints;
    const url = baseProfile + getProfile;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  updateProfile: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseProfile, updateProfile } = Endpoints;
    const url = baseProfile + updateProfile;
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    };
    return ApiCallPut(url, formData, headers);
  },
  passChange: async (otp, newPass, cNewPass, oldPass) => {
    const token = sessionStorage.getItem("token");
    const { baseProfile, passChange } = Endpoints;
    const url = baseProfile + passChange;
    const params = {
      otp: otp,
      newPassword: newPass,
      confirmNewPassword: cNewPass,
      oldPassword: oldPass
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },

  forgotPassword: async (email, otp, newPassword, confirmNewPassword) => {
    const { baseAuth, forgotPassword } = Endpoints;
    const url = baseAuth + forgotPassword;
    const params = {
      email,
      otp,
      newPassword,
      confirmNewPassword
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  createTicket: async (formData) => {
    const token = sessionStorage.getItem("token");
    const { baseProfile, createTicket } = Endpoints;
    const url = baseProfile + createTicket;
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    };
    return ApiCallPost(url, formData, headers);
  },

}








export default AuthService;