import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom"

const Header = () => {

    const navigate = useNavigate()

    // const [sidebar, setSidebar] = useState('false')

    // useEffect(()=>{
    //     if(sidebar){
    //         var element = document.querySelector('.sidebar-fixed');
    //         element.classList.add('sidebar-active');
    //     }else{
    //         var element = document.querySelector('.sidebar-fixed');
    //         element.classList.remove('sidebar-active');
    //     }
    // },[sidebar])

    const hideMenu = () => {
        var element = document.querySelector('.navbar-collapse');
        element.classList.remove('show');
    }



    return (
        <header className="heading" id="LandingHeader">
            <nav className="navbar navbar-expand-md navbar-light">
                <div className="container">
                    <Link to="/" className="navbar-brand">
                        <img className=" " src="assets/img/logo.svg" alt="" />
                    </Link>

                    <div className="collapse navbar-collapse" id="navbarNav">
                        <div className="m-auto navbar-nav">
                            <a href="#about" onClick={() => { hideMenu(); navigate("/") }} >About Us</a>
                            <a href="#whycus" onClick={() => { hideMenu(); navigate("/") }}  > Why Choose us </a>
                            <a href="#benefits" onClick={() => { hideMenu(); navigate("/") }}>  How it works </a>
                            <a href="#community" onClick={() => { hideMenu(); navigate("/") }}> Follow Us </a>
                        </div>
                    </div>

                    <div className="header-buttons">
                        <Link to="/login" className="boxedBtn_border" >Sign In</Link>
                        <Link to="/signup" className="boxedBtn">Sign Up  </Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>

                </div>
            </nav>
        </header>
    )
}

export default Header