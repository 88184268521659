import { useEffect, useState } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import logo from "../config/img/logo.svg";
import heroTop from "../config/img/heroTop-shap.svg";
import userThumb from "../config/img/user_thumb.png";
import { alertErrorMessage } from "../config/Utils/CustomAlertMessage";
import AuthService from "../api/services/AuthService";
import { Endpoints } from "../api/apiConfig/Endpoints";
import LoaderHelper from "../config/Utils/Loading/LoaderHelper";

const Sidebar = () => {
  const [sidebar, setSidebar] = useState(false);
  const [activeTab, setActiveTab] = useState("dashboard");
  const [profileData, setProfileData] = useState("");
  const [kycData, setKycData] = useState("");
  const [adminReply, setAdminReply] = useState("");
  const [seenMessage, setSeenMessage] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const element = document.querySelector(".sidebar-fixed");
    if (sidebar) {
      element.classList.add("sidebar-active");
    } else {
      element.classList.remove("sidebar-active");
    }
  }, [sidebar]);

  const closeDropdown = () => {
    const element = document.querySelector("#exampledropdownDropdown3");
    if (element) {
      element.classList.remove("show");
    }
  };

  useEffect(() => {
    handleProfileData();
  }, []);

  const handleProfileData = async () => {
    const result = await AuthService.getProfileData();
    if (result.success) {
      try {
        setProfileData(result?.data);
        setKycData(result?.data?.kyc_id);
      } catch (error) {
        alertErrorMessage(result?.message);
      }
    } else {
      alertErrorMessage(result.message);
    }
  };

  const handleLogout = () => {
    sessionStorage.clear();
    navigate('/');
  };

  useEffect(() => {
    const handleMyTicket = async () => {
      LoaderHelper.loaderStatus(true);
      try {
        const result = await AuthService.getTicketList();
        if (result.success) {
          LoaderHelper.loaderStatus(false);
          setAdminReply(result?.data?.seen);
        } else {
          alertErrorMessage(result.message);
        }
      } catch (error) {
        alertErrorMessage(error.message);
      } finally {
        LoaderHelper.loaderStatus(false);
      }
    };
    handleMyTicket();
  }, []);

  useEffect(() => {
    const handleNotification = async () => {
      LoaderHelper.loaderStatus(true);
      try {
        const result = await AuthService.getNotification();
        if (result?.success) {
          LoaderHelper.loaderStatus(false);
          setSeenMessage(result?.seen);
        } else {
          alertErrorMessage(result.message);
        }
      } catch (error) {
        alertErrorMessage(error.message);
      } finally {
        LoaderHelper.loaderStatus(false);
      }
    };
    handleNotification();
  }, []);


  return (
    <div className="main">
      <div className="hero inner_page1 pt-0 pb-0">
        <figure className="heroTopShap">
          <img src={heroTop} alt="" />
        </figure>
        <div className="header_top">
          <div className="h_row">
            <div className="h_left">
              <Link to="/account/dashboard" className="ms-2">
                <img src={logo} className="img-fluid" alt="Logo" />
              </Link>
            </div>
            <div className="h_right">
              <button
                onClick={() => setSidebar(!sidebar)}
                className="nav-toggle quick-nav-icon text-white"
              >
                <i className="ri-menu-4-fill"></i>
              </button>
            </div>
          </div>
        </div>

        <div className="dashboard_wrapper">
          <div id="sidebar" className="sidebar sidebar-fixed">
            <div className="sidebar-element sidebar-head">
              <div className="sidebar-brand">
                <Link className="logo-link" to="/account/dashboard" onClick={() => setSidebar(false)}>
                  <img className="logo-light logo-img" src={logo} alt="logo" />
                </Link>
              </div>
              <div className="menu-trigger me-n2" onClick={() => setSidebar(false)}>
                <button className="nav-toggle quick-nav-icon d-xl-none">
                  <em className="icon ri-arrow-left-line"></em>
                </button>
              </div>
            </div>
            <div className="sidebar-content">
              <div className="sidebar-menu">
                <div className="sidebar-header d-flex align-items-center p-4 pt-md-0">
                  <img src={profileData?.profileImage ? Endpoints?.baseurl + profileData?.profileImage : userThumb} alt="user avatar" />
                  <div className="ms-3 title">
                    <h1 className="h5">
                      <small>Welcome </small> {profileData?.fullName?.toUpperCase()}
                    </h1>
                    <p style={{ fontSize: "12px" }}>
                      {profileData?.email}
                      {profileData?.kycVerified === 2 ? (
                        <i style={{ fontSize: "20px" }} className="ms-1 ri-checkbox-circle-fill text-success"></i>
                      ) : (
                        <i style={{ fontSize: "20px" }} className="ms-1 ri-time-line text-danger"></i>
                      )}
                    </p>
                  </div>
                </div>
                <ul className="menu">
                  <li className="menu-heading">
                    <h6 className="overline-title text-primary-alt"> Menu </h6>
                  </li>
                  <li className="menu-item">
                    <Link
                      className={`menu-link ${activeTab === "dashboard" ? "active" : ""}`}
                      to="dashboard"
                      onClick={() => { setActiveTab("dashboard"); closeDropdown(); setSidebar(false); }}
                    >
                      <span className="menu-icon">
                        <i className="ri-dashboard-line"></i>
                      </span>
                      <span className="menu-text">Dashboard</span>
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link
                      className={`menu-link ${activeTab === "kyc" ? "active" : ""}`}
                      to="kyc"
                      onClick={() => { setActiveTab("kyc"); closeDropdown(); setSidebar(false); }}
                    >
                      <span className="menu-icon">
                        <i className="ri-user-follow-line"></i>
                      </span>
                      <span className="menu-text"> KYC</span>
                    </Link>
                  </li>
                  <li className="menu-item dropdown">
                    <a
                      href="#exampledropdownDropdown3"
                      data-bs-toggle="collapse"
                      className={`menu-link item-link ${activeTab === "account" ? "active" : ""}`}
                      onClick={() => setActiveTab("account")}
                    >
                      <span className="menu-icon">
                        <i className="ri-user-line"></i>
                      </span>
                      <span className="menu-text"> Account </span>
                    </a>
                    <ul className="collapse list-unstyled" id="exampledropdownDropdown3">
                      <li>
                        <Link
                          className="sidebar-link"
                          to="myProfile"
                          onClick={() => { setActiveTab("account"); closeDropdown(); setSidebar(false); }}
                        >
                          My Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="sidebar-link"
                          to="editProfile"
                          onClick={() => { setActiveTab("account"); closeDropdown(); setSidebar(false); }}
                        >
                          Edit Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="sidebar-link"
                          to="changePassword"
                          onClick={() => { setActiveTab("account"); closeDropdown(); setSidebar(false); }}
                        >
                          Change Password
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item">
                    <Link
                      to="helpCenter"
                      className={`menu-link ${activeTab === "helpCenter" ? "active" : ""}`}
                      onClick={() => { setActiveTab("helpCenter"); closeDropdown(); setSidebar(false); }}
                    >
                      <span className="menu-icon">
                        <i className="ri-customer-service-2-line"></i>
                      </span>
                      <span className="menu-text"> Help Center </span>
                      {seenMessage ? (
                        <span
                          style={{
                            position: 'relative',
                            top: '-12px',
                            fontSize: '30px',
                            fontWeight: 'bold',
                            lineHeight: '1',
                            color: 'red',
                          }}
                        >
                          .
                        </span>
                      ) : null}
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link
                      to="referralList"
                      className={`menu-link ${activeTab === "referralList" ? "active" : ""}`}
                      onClick={() => { setActiveTab("referralList"); closeDropdown(); setSidebar(false); }}
                    >
                      <span className="menu-icon">
                        <i className="ri-user-shared-2-line"></i>
                      </span>
                      <span className="menu-text"> Refer & Earn </span>
                    </Link>
                  </li>
                  <li className="menu-item" onClick={handleLogout}>
                    <a className="menu-link menu-logout" href="/" >
                      <span className="menu-icon">
                        <i className="ri-logout-circle-r-line"></i>
                      </span>
                      <span className="menu-text"> Logout </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
