import React from 'react';
import Routing from "./config/routing";
import LoaderHelper from './config/Utils/Loading/LoaderHelper';
import Loading from './config/Utils/Loading';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <div className="App">
      <>
        <Routing />
        <Loading ref={ref => LoaderHelper.setLoader(ref)} />
        <ToastContainer />
      </>
    </div>
  );
}

export default App;
