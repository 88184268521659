import { useEffect, useMemo, useState } from "react";
import { alertErrorMessage, alertSuccessMessage, } from "../../config/Utils/CustomAlertMessage";
import DefaultInput from "../../config/DefaultInput";
import { notEqualsZero, aadharNum, passport, } from "../../config/Utils/Validation";
import { drivingLicense, documentNum, validateEmail, validPassword, validPasswordLength, } from "../../config/Utils/Validation";
import AuthService from "../../api/services/AuthService";
import moment from "moment";
import countryList from "react-select-country-list";
import LoaderHelper from "../../config/Utils/Loading/LoaderHelper";
import { Link } from "react-router-dom";

const Kyc = () => {
  const options = useMemo(() => countryList().getData(), []);
  const [kycVerified, setkycVerified] = useState("");
  const [infoCountry, setInfoCountry] = useState("+91");
  const [kycType, setKycType] = useState("Personal");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [infoDob, setInfoDob] = useState("");
  const [docType, setDocType] = useState("Aadhar");
  const [address, setAddress] = useState("");
  const [infoState, setInfoState] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [docNum, setDocNum] = useState("");
  const [gender, setGender] = useState("male");
  const [localSelfie, setLocalSelfie] = useState("");
  const [isShow, setIsShow] = useState(1);
  const [localFront, setLocalFront] = useState("");
  const [localBack, setLocalBack] = useState("");
  const [email, setEmail] = useState("");
  const [rejectReason, setRejectReason] = useState("");
  const [kyc2fa, setKyc2fa] = useState("");
  const [mobileNumber, setMobileNumber] = useState("+91");
  const [otp, setOtp] = useState("");


  const handleKyc = async (localFront, localBack, localSelfie, infoCountry, kycType, firstName, lastName, gender, infoDob, address, infoState, city, zipCode, docType, docNum, otp, email) => {
    var formData = new FormData();
    formData.append("document_front_image", localFront);
    formData.append("document_back_image", localBack);
    formData.append("user_selfie", localSelfie);
    formData.append("address", address);
    formData.append("city", city);
    formData.append("state", infoState);
    formData.append("country_code", infoCountry);
    formData.append("document_number", docNum);
    formData.append("dob", infoDob);
    formData.append("zip_code", zipCode);
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("kyc_type", kycType);
    formData.append("gender", gender);
    formData.append("document_type", docType);
    formData.append(" mobile_number", mobileNumber);
    formData.append("otp", otp);
    formData.append("email", email);

    LoaderHelper.loaderStatus(true);
    await AuthService.kycVerification(formData).then(async (result) => {
      if (result?.success) {
        LoaderHelper.loaderStatus(false);
        try {
          alertSuccessMessage(result?.message);
          handleResetInput();
          handleProfileData();
          window.scrollTo({ top: 0, behavior: "smooth" });
        } catch (error) {
          alertErrorMessage(result?.message);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result?.message);
      }
    });
  };

  useEffect(() => {
    handleProfileData();
  }, []);
  const handleProfileData = async () => {
    const result = await AuthService.getProfileData();
    if (result.success) {
      try {
        setkycVerified(result?.data?.kycVerified);
        setEmail(result?.data?.email);
        setRejectReason(result?.data?.kyc_reject_reason);
        setFirstName(result?.data?.firstName);
        setLastName(result?.data?.lastName);
        setMobileNumber(result?.data?.country_code + " " + result?.data?.mobile_number);
      } catch (error) {
        alertErrorMessage(result?.message);
      }
    } else {
      alertErrorMessage(result.message);
    }
  };
  const verifyAgain = () => {
    setkycVerified("4");
  };
  const handleSelected = (type) => {
    setIsShow(type);
  };

  const OtpHandler = async (email) => {
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.sendOtp(email);
      if (result.success) {
        handleProfileData();
        alertSuccessMessage(result.message);
      } else {
        alertErrorMessage(result.message);
      }
    } catch (error) {
      alertErrorMessage(error.message);
    } finally {
      LoaderHelper.loaderStatus(false);
    }
  };
  const getEighteenYearsAgoDate = () => {
    let eighteenYearsAgo = new Date();
    eighteenYearsAgo = eighteenYearsAgo.setFullYear(
      eighteenYearsAgo.getFullYear() - 18
    );
    eighteenYearsAgo = moment(eighteenYearsAgo).format("YYYY-MM-DD");
    return eighteenYearsAgo;
  };

  const handleResetInput = () => {
    setInfoCountry("");
    // setKycType("");
    setFirstName("");
    setLastName("");
    setInfoDob("");
    setAddress("");
    setInfoState("");
    setCity("");
    setZipCode("");
    setDocNum("");
    setLocalFront("");
    setLocalBack("");
    setLocalSelfie("");
    setMobileNumber("");
    setOtp("");
  };


  const handleChangeIdentity = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
      const maxSize = 2 * 1024 * 1024; // 2MB
      if (allowedTypes.includes(file.type) && file.size <= maxSize) {
        const imgData = URL.createObjectURL(file);
        setLocalFront(file);
        alertSuccessMessage(file?.name)
      } else {
        if (!allowedTypes.includes(file.type)) {
          alertErrorMessage("Only PNG, JPEG, and JPG file types are allowed.");
        } else {
          alertErrorMessage("Max image size is 2MB.");
        }
      }
    }
  };


  const handleChangeIdentity2 = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
      const maxSize = 2 * 1024 * 1024; // 2MB
      if (allowedTypes.includes(file.type) && file.size <= maxSize) {
        const imgData = URL.createObjectURL(file);
        setLocalBack(file);
        alertSuccessMessage(file?.name)
      } else {
        if (!allowedTypes.includes(file.type)) {
          alertErrorMessage("Only PNG, JPEG, and JPG file types are allowed.");
        } else {
          alertErrorMessage("Max image size is 2MB.");
        }
      }
    }
  };



  return (
    <>
      {/* <!-- main wrapper --> */}
      <div class="wrap">
        <div class=" content container-fluid">
          <div class="row g-4 justify-content-center ">
            <div class="col-lg-12">
              <div class="block-head block-head-sm">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="block-head-content">
                    <h3 class=" text-white mb-0">
                      <i class="ri-user-follow-line me-3"></i>
                      KYC
                    </h3>
                  </div>
                  <div class="block-head-content d-none d-xl-block"></div>
                </div>
              </div>
            </div>
            {kycVerified == 1 ? (
              <div className="card mb-5">
                <div className="card-body create-item-wrapper create-item-wrapper-kyc">
                  <div className="form-field-wrapper kyc_wrapper ">
                    <div className="rightsidebox">
                      <div className="kyc_nofti kyc_done">
                        <div className="sc-bdfBQB sc-kmATbt fOxqyX dzKkzw">
                          <div className={`check_bar ${!email ? "" : "active"}`}><i className="ri-check-fill"></i>
                            <h5> Email</h5>
                          </div>
                          <div className={`check_bar ${kyc2fa == "0" ? "" : "active"}`}>
                            <i className="ri-check-fill"></i>
                            <h5> Security </h5>
                          </div>
                          <div className="check_bar ">
                            <i className="ri-check-fill"></i>
                            <h5> Welcome </h5>
                          </div>
                        </div>
                        <hr />
                        <div className="d-kyc_sec ">
                          <div>
                            <h4 className="text-white">
                              <strong>KYC Pending</strong>
                            </h4>
                            <p>
                              Your RCB Account is &nbsp;
                              <strong style={{ color: "#FFC50A" }}>
                                Pending &nbsp;
                              </strong>
                              for Verification
                            </p>
                          </div>
                          <button type="button" style={{ color: "white", background: "linear-gradient(180deg, #FFC50A, #F6821F)", }}
                            className="btn custom-btn custom-border-btn custom-border-btn-white me-2" onClick={() => {
                              window.location.href = "/account/dashboard";
                            }}>
                            <span style={{ color: "black", fontWeight: "600" }}>
                              Back to Home
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : kycVerified == 2 ? (
              <div className="card mb-5">
                <div className="card-body create-item-wrapper create-item-wrapper-kyc">
                  <div className="form-field-wrapper kyc_wrapper ">
                    <div className="rightsidebox">
                      <div className="kyc_nofti kyc_done">
                        <div className="sc-bdfBQB sc-kmATbt fOxqyX dzKkzw">
                          <div className={`check_bar ${!email ? "" : "active"}`}>
                            <i className="ri-check-fill"></i>
                            <h5> Email</h5>
                          </div>
                          <div className={`check_bar ${kyc2fa == "0" ? "" : "active"}`}>
                            <i className="ri-check-fill"></i>
                            <h5> Security </h5>
                          </div>
                          <div className="check_bar ">
                            <i className="ri-check-fill"></i>
                            <h5> Welcome </h5>
                          </div>
                        </div>
                        <hr />
                        <div className="d-kyc_sec">
                          <div className="d-kyc_sec col-12">
                            <div>
                              <h4 className="text-success pb-0">
                                Congratulations
                              </h4>
                              <p>Your RCB account Kyc is Approved</p>
                            </div>
                            <Link to="/account/dashboard" className="btn custom-btn justify-content-center" style={{
                              color: "black", background: "linear-gradient(180deg, #FFC50A, #F6821F)",
                            }}>
                              <span>Choose Packages</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : kycVerified == 3 ? (
              <div className="card mb-5">
                <div className="card-body create-item-wrapper create-item-wrapper-kyc">
                  <div className="form-field-wrapper kyc_wrapper ">
                    <div className="rightsidebox">
                      <div className="kyc_nofti kyc_done">
                        <div className="sc-bdfBQB sc-kmATbt fOxqyX dzKkzw">
                          <div className={`check_bar ${!email ? "" : "active"}`}>
                            <i className="ri-check-fill"></i>
                            <h5> Email</h5>
                          </div>
                          <div className={`check_bar ${kyc2fa == "0" ? "" : "active"}`}>
                            <i className="ri-check-fill"></i>
                            <h5> Security </h5>
                          </div>
                          <div className="check_bar ">
                            <i className="ri-check-fill"></i>
                            <h5> Welcome </h5>
                          </div>
                        </div>
                        <hr />
                        <div className="d-kyc_sec">
                          <div>
                            <h5>
                              Your RCB account Kyc is &nbsp;
                              <strong>Rejected</strong>
                            </h5>
                            <span className="text-danger"></span>
                            <p className="text-danger mt-3 solid" style={{ fontSize: "24px", fontWeight: "bold" }}>
                              {rejectReason}
                            </p>
                          </div>
                          <button className="btn custom-btn justify-content-center btn-danger" onClick={verifyAgain}>
                            <span>Verify Again</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div class="col-lg-12 mx-auto ">
                <div class="kyc_col">
                  <form class="card no-shadow">
                    <div class="card-body create-item-wrapper create-item-wrapper-kyc custom-form ">
                      <div class="mb-3 pt-6">
                        <h5 class="title ">Personal Information</h5>
                      </div>
                      <div class="form-field-wrapper mb-4 ">
                        <div class="row">
                          <div class="col-md-6 ">
                            <div class="field-box  mb-3">
                              <label for="name" class="form-label">
                                First Name<span class="text-danger">*</span>
                              </label>
                              <input id="name" type="text" placeholder=" Enter your first name" class="form-control" name="firstName" value={firstName === "undefined" ? "" : firstName} onChange={(e) => setFirstName(e.target.value)} required validatetext={firstName} disabled />
                            </div>
                          </div>
                          <div class="col-md-6 ">
                            <div class="field-box mb-3">
                              <label for="name" class="form-label">
                                Last Name<span class="text-danger">*</span>
                              </label>
                              <input id="name" type="text" placeholder="Enter your last name" class="form-control" name="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} required disabled />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="field-box mb-3">
                              <label for="name" class="form-label">
                                Email Address<span class="text-danger">*</span>
                              </label>
                              <input id="name" type="text" placeholder="Enter your Email name" class="form-control" name="emailid" value={email} onChange={(e) => setEmail(e.target.value)} required disabled />
                            </div>
                          </div>


                          <div class="col-md-6 ">
                            <div class="field-box mb-3">
                              <label for="name" class="form-label">
                                Mobile Number<span class="text-danger">*</span>
                              </label>
                              <input id="name" type="text" placeholder="Enter your mobile number" class="form-control" name="mobileNumber" value={`+${mobileNumber}`} onChange={(e) => setMobileNumber(e.target.value)} required disabled />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="field-box mb-3">
                              <label for="name" class="form-label">
                                Date of birth<span class="text-danger">*</span>
                              </label>
                              <input id="name" type="date" placeholder="" class="form-control" name="infoDob" max={getEighteenYearsAgoDate()} value={infoDob} onChange={(e) => setInfoDob(e.target.value)} required />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="pb-2" for="">
                                Gender<span class="text-danger">*</span>
                              </label>
                              <div class="d-flex align-items-center mb-1">
                                <div class="form-check me-5">
                                  <input id="male" class="form-check-input" type="radio" name="gender" value="male" onClick={(event) => {
                                    if (event.target.value !== gender) { setGender(event.target.value); }
                                  }} checked={gender === "male"} required />
                                  <label class="form-check-label ms-2" htmlhtmlfor="male">Male</label>
                                </div>
                                <div class="form-check me-5">
                                  <input id="female" class="form-check-input" type="radio" name="gender" value="female" onClick={(event) => {
                                    if (event.target.value !== gender) { setGender(event.target.value); }
                                  }} checked={gender === "female"} required />
                                  <label class="form-check-label ms-2" htmlhtmlfor="female">
                                    Female
                                  </label>
                                </div>
                                <div class="form-check me-5">
                                  <input id="female" class="form-check-input" type="radio" name="gender" value="other" onClick={(event) => {
                                    if (event.target.value !== gender) { setGender(event.target.value); }
                                  }} checked={gender === "other"} required />
                                  <label class="form-check-label ms-2" htmlhtmlfor="other">
                                    Other
                                  </label>
                                </div>
                              </div>
                            </div>
                            <hr />
                          </div>
                          <div class="col-12">
                            <h6 class="acc_title mt-2">
                              Address Information
                              <span class="text-danger">*</span>
                            </h6>
                          </div>

                          <div class="col-md-6 mb-4">
                            <div class="field-box">
                              <label for="name" class="form-label">
                                Pin Code<span class="text-danger">*</span>
                              </label>
                              <input id="name" type="text" placeholder="" class="form-control" name="zipCode" value={zipCode} onChange={(e) => setZipCode(e.target.value)} required />
                            </div>
                          </div>
                          <div class="col-md-6 mb-4">
                            <div class="field-box">
                              <label for="name" class="form-label">
                                City<span class="text-danger">*</span>
                              </label>
                              <input id="name" type="text" placeholder="" class="form-control" name="city" value={city} onChange={(e) => setCity(e.target.value)} required />
                            </div>
                          </div>
                          <div className="col-md-6 mb-4">
                            <div className="field-box">
                              <label htmlFor="state" className="form-label">
                                State<span className="text-danger">*</span>
                              </label>
                              <select id="state" className="form-control" name="State" value={infoState} onChange={(e) => setInfoState(e.target.value)}
                                required style={{ backgroundColor: '#212820' }}>
                                <option value="">Select State</option>
                                <option value="Andhra Pradesh">Andhra Pradesh</option>
                                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                <option value="Assam">Assam</option>
                                <option value="Bihar">Bihar</option>
                                <option value="Chhattisgarh">Chhattisgarh</option>
                                <option value="Goa">Goa</option>
                                <option value="Gujarat">Gujarat</option>
                                <option value="Haryana">Haryana</option>
                                <option value="Himachal Pradesh">Himachal Pradesh</option>
                                <option value="Jharkhand">Jharkhand</option>
                                <option value="Karnataka">Karnataka</option>
                                <option value="Kerala">Kerala</option>
                                <option value="Madhya Pradesh">Madhya Pradesh</option>
                                <option value="Maharashtra">Maharashtra</option>
                                <option value="Manipur">Manipur</option>
                                <option value="Meghalaya">Meghalaya</option>
                                <option value="Mizoram">Mizoram</option>
                                <option value="Nagaland">Nagaland</option>
                                <option value="Odisha">Odisha</option>
                                <option value="Punjab">Punjab</option>
                                <option value="Rajasthan">Rajasthan</option>
                                <option value="Sikkim">Sikkim</option>
                                <option value="Tamil Nadu">Tamil Nadu</option>
                                <option value="Telangana">Telangana</option>
                                <option value="Tripura">Tripura</option>
                                <option value="Uttar Pradesh">Uttar Pradesh</option>
                                <option value="Uttarakhand">Uttarakhand</option>
                                <option value="West Bengal">West Bengal</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-6 mb-4">
                            <div class="field-box">
                              <label for="name" class="form-label">
                                Address<span class="text-danger">*</span>
                              </label>
                              <input id="name" type="text" placeholder="" class="form-control" name="address" value={address} onChange={(e) => setAddress(e.target.value)}
                                required />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3 pt-6">
                        <h5 class="title ">
                          Document Verification
                          <span class="text-danger">*</span>
                        </h5>
                      </div>
                      <div class="form-field-wrapper mb-4 ">
                        <div class="row">
                          <div class="col-md-6  mb-4">
                            <div className="field-box">
                              <label htmlFor="sizes" className="form-label">
                                Select Document Type
                                <span class="text-danger">*</span>
                              </label>
                              <select id="royality" className="form-control form-select" onChange={(event) => {
                                setDocNum(""); setDocType(event.target.value); handleSelected(event.target.value === "Aadhar" ? 1 : event.target.value === "Driving Licence" ? 2 : event.target.value === "Other" ? 3 : event.target.value === "Passport" ? 4 : undefined);
                              }}                              >
                                <option defaultValue value="Aadhar">Aadhar card</option>
                                <option value="Driving Licence">Driving Licence</option>
                                <option value="Passport">Passport </option>
                                <option value="Other">Other</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6 mb-4">
                            <div className={`field-box ${isShow !== 1 && "d-none"}`} id="aadhar">
                              <label htmlFor="name" className="form-label">
                                Aadhar Number
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <DefaultInput id="name" type="text" className="form-control" required name="aadhar" value={docNum} errorstatus={aadharNum(docNum) !== undefined && notEqualsZero(docNum)} errormessage={aadharNum(docNum)} onChange={(event) => {
                                const value = event.target.value; if (value.length <= 12 && /^\d*$/.test(value)) { setDocNum(value); }
                              }} onBlur={() => {
                                if (docNum.length !== 12) {
                                  // handle the error state, e.g., set an error message
                                }
                              }} />
                            </div>
                            <div className={`field-box ${isShow !== 2 && "d-none"}`}>
                              <label htmlFor="name" className="form-label">
                                Driving Licence Number
                                <span class="text-danger">*</span>
                              </label>
                              <DefaultInput id="name" type="text" className="form-control" required name="driving" value={docNum} errorstatus={documentNum(docNum) !== undefined && notEqualsZero(docNum)} errormessage={documentNum(docNum)} onChange={(event) => {
                                const value = event.target.value; if (value.length <= 6 && /^\d*$/.test(value)) {
                                  setDocNum(value);
                                }
                              }} onBlur={() => {
                                if (docNum.length !== 16) {
                                  // handle the error state, e.g., set an error message
                                }
                              }} />
                            </div>
                            <div className={`field-box ${isShow !== 4 && "d-none"}`}>
                              <label htmlFor="name" className="form-label">
                                Passport Number
                                <span class="text-danger">*</span>
                              </label>
                              <DefaultInput id="name" type="text" className="form-control" required name="driving" value={docNum} errorstatus={passport(docNum) !== undefined && notEqualsZero(docNum)} errormessage={passport(docNum)} onChange={(event) => { const value = event.target.value; if (value.length <= 12 && /^\d*$/.test(value)) { setDocNum(value); } }} onBlur={() => {
                                if (docNum.length !== 12) {
                                  // handle the error state, e.g., set an error message
                                }
                              }} />
                            </div>
                            <div className={`field-box ${isShow !== 3 && "d-none"}`}>
                              <label htmlFor="name" className="form-label">
                                Other Document Number
                                <span class="text-danger">*</span>
                              </label>
                              <DefaultInput id="name" type="text" className="form-control" required name="aadhar" value={docNum} errorstatus={documentNum(docNum) !== undefined && notEqualsZero(docNum)} errormessage={documentNum(docNum)} onChange={(event) => setDocNum(event.target.value)} />
                            </div>
                          </div>
                          <div class="col-md-6 upload-area upload_xl">
                            <div class="upload-formate mb-3">
                              <h6 class="title mb-1">
                                Front Image<span class="text-danger">*</span>
                              </h6>
                              <div class=" text-smallest mb-1">
                                <span>
                                  (Only JPEG, PNG &amp; JPG formats and file
                                  size upto 2MB are supported)
                                </span>
                              </div>
                            </div>
                            {localFront === "" ? (
                              <div className="brows-file-wrapper">
                                <input
                                  name="file"
                                  id="file4"
                                  type="file"
                                  className="inputfile"
                                  data-multiple-caption="{count} files selected"
                                  required
                                  onChange={(e) => handleChangeIdentity(e)}
                                />
                                <label htmlFor="file4" title="No File Chosen">
                                  <i className="ri-upload-cloud-line"></i>
                                  <span className="text-center mb-2">Choose a File</span>
                                  <span className="file-type text-center mt--10">
                                    Drag or choose your file to upload
                                  </span>
                                </label>
                              </div>
                            ) : (
                              <label htmlFor="file4" title="File Uploaded">
                                <i className="text-success ri-check-double-fill"></i>
                                <span className="text-center mb-2">File Uploaded</span>
                                <span className="file-type text-center mt--10">
                                  {localFront?.name}
                                </span>
                              </label>
                            )}
                          </div>
                          <div class="col-md-6 upload-area upload_xl">
                            <div class="upload-formate mb-3">
                              <h6 class="title mb-1">
                                Back Image<span class="text-danger">*</span>
                              </h6>
                              <div class=" text-smallest mb-1">
                                <span>
                                  (Only JPEG, PNG &amp; JPG formats and file
                                  size upto 2MB are supported)
                                </span>
                              </div>
                            </div>
                            {localBack === "" ? (
                              <div class="brows-file-wrapper">
                                <input name="file" id="file4" type="file" class="inputfile" data-multiple-caption="{count} files selected"
                                  required="" onChange={(e) => handleChangeIdentity2(e)} />
                                <label for="file4" title="No File Choosen"><i class="ri-upload-cloud-line"></i>
                                  <span class="text-center mb-2">
                                    Choose a File
                                  </span>
                                  <span class="file-type text-center mt--10">
                                    Drag or choose your file to upload
                                  </span>
                                </label>
                              </div>
                            ) : (
                              <label htmlFor="file4" title="File Uploaded">
                                <i className="text-success ri-check-double-fill"></i>
                                <span className="text-center mb-2">File Uploaded</span>
                                <span className="file-type text-center mt--10">
                                  {localBack?.name}
                                </span>
                              </label>
                            )}
                          </div>
                          <div class="col-md-12">
                            <label className="form-label" htmlFor="exampleInputOTP">Enter OTP</label><span class="text-danger">*</span>
                            <div className="field-otp-box">
                              <input className="form-control" id="exampleInputOTP" type="number" name="otp" autoComplete="off" value={otp} onChange={(e) => setOtp(e.target.value)} />
                              <button className="btn btn-sm btn_gradient" type="button" onClick={() => OtpHandler(email)}>GET OTP</button>
                            </div>
                          </div>
                          <div class="">
                            <button
                              type="button"
                              class=" mt-4 btn btn-xl btn_gradient btn-block w-100"
                              onClick={() => handleKyc(localFront, localBack, localSelfie, infoCountry, kycType, firstName, lastName, gender, infoDob, address, infoState, city, zipCode, docType, docNum, otp, email)} required disabled={!otp} >
                              <span>Submit for verification</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div >
      </div >
    </>
  );
};

export default Kyc;
