import { Link } from "react-router-dom";

const Transactions = () =>{
    return(
        <>
         <div className="wrap">
                <div className=" content container-fluid"> 
                    <div className="row g-4 justify-content-center " >
                        <div className="col-lg-12" >
                            <div className="block-head block-head-sm">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="block-head-content">
                                        <h3 className=" text-white mb-0"> 
                                            <i className="ri-file-list-3-line me-3"></i>
                                            Transactions   
                                        </h3> 
                                    </div>
                                    
                                    <div className="block-head-content d-none d-xl-block">
                                        <div className="container-fluid py-2">
                                            <nav aria-label="breadcrumb">
                                                <ol className="breadcrumb py-2 px-0">
                                                    <li className="breadcrumb-item"><Link to="/account/dashboard">Home</Link></li> 
                                                    <li className="breadcrumb-item active" aria-current="page"> Transactions </li>
                                                </ol>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-12" >
                            <ul className="nav nav-pills mb-4" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                  <button className="nav-link active" id="pills-Withdraw-tab" data-bs-toggle="pill" data-bs-target="#pills-Withdraw" type="button" role="tab" 
                                  aria-controls="pills-Withdraw" aria-selected="true"> Withdraw History</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                  <button className="nav-link" id="pills-Deposit-tab" data-bs-toggle="pill" data-bs-target="#pills-Deposit" type="button" role="tab" 
                                  aria-controls="pills-Deposit" aria-selected="false"> Deposit History </button>
                                </li> 
                              </ul>

                            <div className="card" > 
                                  <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-Withdraw" role="tabpanel" aria-labelledby="pills-Withdraw-tab">
                                        <div className="table-responsive">
                                            <table className="table wallet_table">
                                                <thead>
                                                    <tr>
                                                        <th>Sr No.</th>
                                                        <th>TXN ID</th>
                                                        <th>Date  </th>
                                                        <th  className="text-center" >Amount</th>
                                                        <th  className="text-end" >Status</th> 
                                                    </tr>
                                                </thead>
                                                <tbody> 
                                                    <tr>
                                                        <td>1</td>
                                                        <td>#02522</td>
                                                        <td> 
                                                            <div className="td_view" >
                                                                12-05-2024
                                                                <br/><small className="text-muted" >01.15 pm</small>
                                                            </div>
                                                        </td>
                                                        <td  className="text-center" >335545</td> 
                                                        <td className="text-end"> 
                                                            <span className="badge bg-success"> Success </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="pills-Deposit" role="tabpanel" aria-labelledby="pills-Deposit-tab">
                                        <div className="table-responsive">
                                            <table className="table wallet_table">
                                                <thead>
                                                    <tr>
                                                        <th>Sr No.</th>
                                                        <th>TXN ID</th>
                                                        <th>Date  </th>
                                                        <th  className="text-center" >Amount</th>
                                                        <th  className="text-end" >Status</th> 
                                                    </tr>
                                                </thead>
                                                <tbody> 
                                                    <tr>
                                                        <td>1</td>
                                                        <td>#02522</td>
                                                        <td> 
                                                            <div className="td_view" >
                                                                12-05-2024
                                                                <br/><small className="text-muted" >01.15 pm</small>
                                                            </div>
                                                        </td>
                                                        <td  className="text-center" >335545</td> 
                                                        <td className="text-end"> 
                                                            <span className="badge bg-danger"> Failed </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> 
                                  </div>
                            </div>
                        </div>


                         

                         
                    </div>
                </div>
            </div>
        </>
    )
}

export default Transactions;