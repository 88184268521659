import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import LoaderHelper from "../../config/Utils/Loading/LoaderHelper";
import DefaultInput from "../../config/DefaultInput";
import { notEqualsZero, validPassword, validPasswordLength } from "../../config/Utils/Validation";
import AuthService from "../../api/services/AuthService";
import { alertErrorMessage } from "../../config/Utils/CustomAlertMessage";
const ChangePassword = () => {
    const [otp, setOtp] = useState('');
    const [newPass, setNewPass] = useState('');
    const [cNewPass, setcNewPass] = useState('');
    const [oldPass, setOldPass] = useState('');
    const [email, setEmail] = useState("");
    const [passwordType, setPasswordType] = useState("password");
    const [reEnterPasswordType, setReEnterPasswordType] = useState("password");
    const [message, setMessage] = useState({ text: "", type: "" });
    const [passwordMatchMessage, setPasswordMatchMessage] = useState("");

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        switch (name) {
            case "newpassword":
                setNewPass(value);
                setPasswordMatchMessage(cNewPass === value ? "Password matched" : "Password does not match");
                break;
            case "cnewpassword":
                setcNewPass(value);
                setPasswordMatchMessage((!value && !newPass) ? "" : value === newPass ? "Password matched" : "Password does not match");
                break;
            case "oldpassword":
                setOldPass(value);
                break;
            case "otp":
                setOtp(value);
                break;
            default:
                break;
        }
    };

    const togglePassword = () => {
        setPasswordType(prevType => (prevType === "password" ? "text" : "password"));
    };

    const toggleConfirmPassword = () => {
        setReEnterPasswordType(prevType => (prevType === "password" ? "text" : "password"));
    };

    const handlePassChange = async (otp, newPass, cNewPass, oldPass, email) => {
        if (!otp || !newPass || !cNewPass || !oldPass || newPass !== cNewPass || validPassword(newPass) !== undefined || validPassword(oldPass) !== undefined) {
            alertErrorMessage("Dont try to be over smart");
            return
        }
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.passChange(otp, newPass, cNewPass, oldPass, email);
            if (result.success) {
                setMessage({ text: result.message, type: "success" });
                setNewPass("");
                setcNewPass("");
                setOldPass("");
            } else {
                setMessage({ text: result.message, type: "error" });
            }
        } catch (error) {
            setMessage({ text: error.message, type: "error" });
        } finally {
            LoaderHelper.loaderStatus(false);
        }
    };

    const handleProfileData = async () => {
        try {
            const result = await AuthService.getProfileData();
            if (result.success) {
                setEmail(result?.data?.email);
            } else {
                setMessage({ text: result.message, type: "error" });
            }
        } catch (error) {
            setMessage({ text: error.message, type: "error" });
        }
    };

    const OtpHandler = async (email) => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.sendOtp(email);
            if (result.success) {
                setMessage({ text: result.message, type: "success" });
            } else {
                setMessage({ text: result.message, type: "error" });
            }
        } catch (error) {
            setMessage({ text: error.message, type: "error" });
        } finally {
            LoaderHelper.loaderStatus(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (newPass !== cNewPass) {
            setMessage({ text: "New password and confirm password do not match", type: "error" });
            return;
        }
        handlePassChange(otp, newPass, cNewPass, oldPass, email);
    };

    useEffect(() => {
        handleProfileData();
    }, []);

    return (
        <>
            <div className="wrap">
                <div className="content container-fluid">
                    <div className="row g-4 justify-content-center">
                        <div className="col-lg-12">
                            <div className="block-head block-head-sm">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="block-head-content">
                                        <h3 className="text-white mb-0">
                                            <i className="ri-lock-password-line me-3"></i>
                                            Change Password
                                        </h3>
                                    </div>
                                    <div className="block-head-content d-none d-xl-block">
                                        <div className="container-fluid py-2">
                                            <nav aria-label="breadcrumb">
                                                <ol className="breadcrumb py-2 px-0">
                                                    <li className="breadcrumb-item"><Link to="/account/dashboard">Home</Link></li>
                                                    <li className="breadcrumb-item active" aria-current="page">Account</li>
                                                    <li className="breadcrumb-item active" aria-current="page">Change Password</li>
                                                </ol>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-lg-8 m-auto">
                            <div className="card h-100">
                                <div className="card-body">
                                    {message.text && (
                                        <div className={`alert ${message.type === 'success' ? 'alert-success' : 'alert-danger'}`}>
                                            {message.text}
                                        </div>
                                    )}
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-3 position-relative">
                                            <label className="form-label" htmlFor="exampleInputMember">Enter Current Password</label><span style={{ color: "red" }}> *</span>
                                            <input className="form-control" id="exampleInputMember" type={passwordType} name="oldpassword" placeholder="Enter current password" value={oldPass} onChange={handleInputChange} />
                                            <button type="button" className="btn btn-outline-secondary position-absolute end-0 top-50 translate-middle-y" onClick={togglePassword}>
                                                {passwordType === "password" ? <i className="ri-lock-line" /> : <i className="ri-lock-unlock-line " />}
                                            </button>
                                        </div>
                                        <div className="mb-3 position-relative">
                                            <label className="form-label" htmlFor="exampleInputNewPassword">Enter New Password</label><span style={{ color: "red" }}> *</span>
                                            <div className="field-otp-box">
                                                <DefaultInput type={passwordType} value={newPass} placeholder="Enter new password" name="newpassword" id="newpassword" class="form-control"
                                                    autoComplete="off" errorstatus={validPassword(newPass) !== undefined && notEqualsZero(newPass) && validPasswordLength(newPass) ? "true" : "false"}
                                                    onChange={handleInputChange} />
                                            </div>
                                            <button type="button" className="btn btn-outline-secondary position-absolute end-0 top-50 translate-middle-y" onClick={togglePassword}>
                                                {passwordType === "password" ? <i className="ri-lock-line" /> : <i className="ri-lock-unlock-line " />}
                                            </button>
                                        </div>
                                        <div className={`mt-2 text-danger`}>
                                            {validPassword(newPass) !== undefined && validPasswordLength(newPass)}
                                        </div>
                                        <div className="mb-3 position-relative">
                                            <label className="form-label" htmlFor="exampleInputConfirmNewPassword">Confirm New Password</label><span style={{ color: "red" }}> *</span>
                                            <div className="field-otp-box">
                                                <DefaultInput type={reEnterPasswordType} value={cNewPass} placeholder="Confirm new password" name="cnewpassword" id="cnewpassword" class="form-control"
                                                    autoComplete="off" errorstatus={notEqualsZero(cNewPass) && (newPass === cNewPass) ? "true" : "false"}
                                                    onChange={handleInputChange} />
                                            </div>
                                            <button type="button" className="btn btn-outline-secondary position-absolute end-0 top-50 translate-middle-y" onClick={toggleConfirmPassword}>
                                                {reEnterPasswordType === "password" ? <i className="ri-lock-line" /> : <i className="ri-lock-unlock-line " />}
                                            </button>
                                        </div>
                                        <div className={`mt-2 ${passwordMatchMessage === "Password matched" ? 'text-success' : 'text-danger'}`}>
                                            {passwordMatchMessage}
                                        </div>
                                        <div className="mb-3">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="exampleInputOTP">Enter OTP</label><span style={{ color: "red" }}> *</span>
                                                <div className="field-otp-box">
                                                    <input className="form-control" id="exampleInputOTP" type="text" name="otp" placeholder="Enter OTP" autoComplete="off" value={otp} onChange={handleInputChange} />
                                                    <button className="btn btn-sm btn_gradient" type="button" onClick={() => OtpHandler(email)}>GET OTP</button>
                                                </div>
                                            </div>
                                        </div>
                                        <button className="btn btn_gradient btn-block w-100 mt-4" type="submit" disabled={!otp || !newPass || !cNewPass || !oldPass || newPass !== cNewPass || validPassword(newPass) !== undefined || validPassword(oldPass) !== undefined}>Change Password</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChangePassword;
