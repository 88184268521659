import { Link } from "react-router-dom";
import userThumb from "../../config/img/user_thumb.png";
import { useEffect, useState } from "react";
import AuthService from "../../api/services/AuthService";
import { alertErrorMessage } from "../../config/Utils/CustomAlertMessage";
import { Endpoints } from "../../api/apiConfig/Endpoints";

const MyProfile = () => {
    const [profileData, setProfileData] = useState('');
    const [kycData, setKycData] = useState('');

    const handleProfileData = async () => {
        const result = await AuthService.getProfileData();
        if (result.success) {
            try {
                setProfileData(result?.data);
                setKycData(result?.data?.kyc_id);
            } catch (error) {
                alertErrorMessage(result?.message);
            }
        } else {
            alertErrorMessage(result.message);
        }
    };

    useEffect(() => {
        handleProfileData();
    }, []);


    return (
        <>
            <div className="wrap">
                <div className="content container-fluid">
                    <div className="row g-4 justify-content-center">
                        <div className="col-lg-12">
                            <div className="block-head block-head-sm">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="block-head-content">
                                        <h3 className="text-white mb-0">
                                            <i className="ri-user-line me-3"></i>
                                            My Profile
                                        </h3>
                                    </div>
                                    <div className="block-head-content d-none d-xl-block">
                                        <div className="container-fluid py-2">
                                            <nav aria-label="breadcrumb">
                                                <ol className="breadcrumb py-2 px-0">
                                                    <li className="breadcrumb-item"><Link to="/account/dashboard">Home</Link></li>
                                                    <li className="breadcrumb-item active" aria-current="page">Account</li>
                                                    <li className="breadcrumb-item active" aria-current="page">My Profile</li>
                                                </ol>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-lg-8 m-auto">
                            <div className="card h-100">
                                <div className="card-body">
                                    <form>
                                        <div className="row mb-3">
                                            <div className="col-xxl-4 col-lg-4">
                                                <div className="avatar-preview">
                                                    <img className="img-fluid" src={profileData?.profileImage ? Endpoints.baseurl + profileData?.profileImage : userThumb} alt="user avatar" />
                                                </div>
                                            </div>
                                            <div className="col-xxl-8 col-lg-8">
                                                <div className="mb-3">
                                                    <label className="form-label mb-0" htmlFor="exampleInputName">Name</label>
                                                    <h5>{profileData?.fullName?.toUpperCase()}</h5>
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label mb-0" htmlFor="exampleInputEmail">Email</label>
                                                    <h6>{profileData?.email}</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="react-bootstrap-table">
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <td>Account Status</td>
                                                        <td>: <b className={profileData?.status === 'ACTIVE' ? 'text-success' : 'text-danger'}>
                                                            {profileData?.status}
                                                            <i className="ms-1 ri-checkbox-circle-fill"></i>
                                                        </b>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Mobile Number</td>
                                                        <td>: <b className="text-white">+ {profileData?.country_code} - {profileData?.mobile_number}</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Gender</td>
                                                        <td>: <b className="text-white">{profileData?.gender}</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Document Type</td>
                                                        <td>: <b className="text-white">{profileData?.document_type}<small className="fw-normal"> ({kycData?.document_number})</small></b></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Country</td>
                                                        <td>: <b className="text-white">{profileData?.country}</b></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        {profileData.kycVerified === 2 ? (
                                            <Link className="btn btn_gradient btn-block w-100 mt-3" to="/account/editProfile">
                                                Edit Profile
                                            </Link>
                                        ) : (
                                            <div className="text-danger" style={{ textAlign: 'center', marginTop: '1rem', fontWeight: 'bold', fontSize: '20px' }}>
                                                Please Complete KYC to Edit Profile
                                            </div>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MyProfile;
