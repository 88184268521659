const appUrl = "https://backend.vrfxbroker.com";
export const deployedUrl = `${window.origin}/`


export const Endpoints = {
    signup: "signup",
    login: "login",
    sendOtp: "request-otp-rcb",
    getProfile: "getprofile",
    updateProfile: "editprofile",
    kycVerification: "submit-kyc",
    getProfileData: "getprofile",
    passChange: "changepassword",
    forgotPassword: "forgotpassword",
    getCoinList: "coin-list",
    getRefferalList: "user_referral_list",
    getRefferalCount: "total_refer_count",
    getRefferalCode: "user_refer_code",
    getTicketList: "my-tickets",
    createTicket: "create-ticket",
    getPackageList: "package-list",
    getNotification: "get-seen-true-message",
    seenTicket: "update-seen-true-message",





    baseurl: `${appUrl}/`,
    baseAuth: `${appUrl}/api/auth/`,
    baseProfile: `${appUrl}/api/profile/`,
    basePackage: `${appUrl}/api/package/`,
}