import { useState } from "react"
import Footer from "../Footer"
import Header from "../Header"
import AuthService from "../../api/services/AuthService"
import LoaderHelper from "../../config/Utils/Loading/LoaderHelper"
import { alertErrorMessage, alertSuccessMessage } from "../../config/Utils/CustomAlertMessage"
import { useNavigate } from "react-router-dom"
import { notEqualsZero, validPassword, validPasswordLength } from "../../config/Utils/Validation"
import DefaultInput from "../../config/DefaultInput"

const ForgotPassword = () => {

    const navigate = useNavigate()
    const [email, setEmail] = useState()
    const [newPassword, setNewPassword] = useState()
    const [confirmNewPassword, setConfirmNewPassword] = useState("")
    const [otp, setOtp] = useState()
    const [passwordType, setPasswordType] = useState("password");
    const [reEnterPasswordType, setReEnterPasswordType] = useState("password");
    const [passwordMatchMessage, setPasswordMatchMessage] = useState("");

    const InputHandler = (e) => {
        e.stopPropagation();
        const { name, value } = e.target;
        switch (name) {
            case "email":
                setEmail(e.target.value)
                break;
            case "newpassword":
                setNewPassword(e.target.value)
                setPasswordMatchMessage(confirmNewPassword === value ? "Password matched" : "Password does not match");
                break;
            case "confirmNewPassword":
                setConfirmNewPassword(e.target.value)
                setPasswordMatchMessage((!value && !newPassword) ? "" : value === newPassword ? "Password matched" : "Password does not match");
                break;
            case "otp":
                setOtp(e.target.value)
                break;
            default:
                break;
        }
    }

    const togglePassword = () => {
        setPasswordType(prevType => (prevType === "password" ? "text" : "password"));
    };

    const toggleConfirmPassword = () => {
        setReEnterPasswordType(prevType => (prevType === "password" ? "text" : "password"));
    };

    const ForgotPasswordHandler = async (email, otp, newPassword, confirmNewPassword) => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.forgotPassword(email, otp, newPassword, confirmNewPassword);
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                alertSuccessMessage(result.message);
                navigate("/login");
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error.message || 'An error occurred');
        }
    };

    const OtpHandler = async (e) => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.sendOtp(email);
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                alertSuccessMessage(result.message);
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error.message || 'An error occurred');
        }
    }

    return (
        <>
            <Header />
            <div className="hero inner_page">
                <figure className="heroTopShap"><img src="assets/img/heroTop-shap.svg" alt="" /></figure>
                <div className="container">
                    <div className="align-items-start justify-content-center row">
                        <div className="col-lg-6 col-12 mx-auto">
                            <form className="custom-form form_card" onSubmit={(e) => { e.preventDefault(); }}>
                                <div className="card-body p-0">
                                    <div className="section-title text-center mb-4 mb-md-5">
                                        <h3 className=" text-gradient"> <span>FORGOT PASSWORD?</span> </h3>
                                        <p>Enter detail for your account recovery.</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-12">
                                            <div className="form-group" >
                                                <input type="email" name="email" id="form-signId" className="form-control email_input" placeholder="Enter Email Address" required value={email} onChange={InputHandler} />
                                                <i className="ri-mail-line"></i>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-12">
                                            <div className="form-group" >
                                                <input type="code" name="otp" id="form-signId" className="form-control email_input" placeholder="Enter OTP" required value={otp} onChange={InputHandler} />
                                                <button className="inlineBtn_fill  btn-sm" type="button" onClick={OtpHandler} >Generate OTP</button>
                                            </div>
                                        </div>
                                        <div className="mb-3 position-relative">
                                            <label className="form-label" htmlFor="exampleInputNewPassword">Enter New Password</label><span style={{ color: "red" }}> *</span>
                                            <div className="field-otp-box">
                                                <DefaultInput type={passwordType} value={newPassword} placeholder="Enter new password" name="newpassword" id="newpassword" class="form-control"
                                                    autoComplete="off" errorstatus={validPassword(newPassword) !== undefined && notEqualsZero(newPassword) && validPasswordLength(newPassword) ? "true" : "false"}
                                                    // errormessage={validPassword(newPass) && validPasswordLength(newPass)}
                                                    onChange={InputHandler} />
                                            </div>
                                            <button type="button" className="btn btn-outline-secondary position-absolute end-0 top-50 translate-middle-y" onClick={togglePassword}>
                                                {passwordType === "password" ? <i className="ri-lock-line" /> : <i className="ri-lock-unlock-line " />}
                                            </button>
                                        </div>
                                        <div className={`mt-2 text-danger`}>
                                            {validPassword(newPassword) !== undefined && validPasswordLength(newPassword)}
                                        </div>
                                        <div className="mb-3 position-relative">
                                            <label className="form-label" htmlFor="exampleInputConfirmNewPassword">Confirm New Password</label><span style={{ color: "red" }}> *</span>
                                            <div className="field-otp-box">
                                                <DefaultInput type={reEnterPasswordType} value={confirmNewPassword} placeholder="Confirm new password" name="confirmNewPassword" id="confirmNewPassword" class="form-control"
                                                    autoComplete="off" errorstatus={notEqualsZero(confirmNewPassword) && (newPassword === confirmNewPassword) ? "true" : "false"}
                                                    onChange={InputHandler} />

                                            </div>
                                            <button type="button" className="btn btn-outline-secondary position-absolute end-0 top-50 translate-middle-y" onClick={toggleConfirmPassword}>
                                                {reEnterPasswordType === "password" ? <i className="ri-lock-line" /> : <i className="ri-lock-unlock-line " />}
                                            </button>

                                        </div>
                                        <div className={`mt-2 ${passwordMatchMessage === "Password matched" ? 'text-success' : 'text-danger'}`}>
                                            {passwordMatchMessage}
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-10 col-12 mx-auto mt-4">
                                        <button
                                            type="submit"
                                            className="inlineBtn_fill w-100 btn-block"
                                            onClick={() => { ForgotPasswordHandler(email, otp, newPassword, confirmNewPassword) }}>
                                            FORGOT PASSWORD
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div >
            <Footer />
        </>
    )
}

export default ForgotPassword