import { Link, useNavigate } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import { useEffect, useState } from "react";
import AuthService from "../../api/services/AuthService";
import LoaderHelper from "../../config/Utils/Loading/LoaderHelper";
import { alertErrorMessage, alertSuccessMessage, alertWarningMessage } from "../../config/Utils/CustomAlertMessage";
import { matchPassword, notEqualsZero, validateEmail, validPassword } from "../../config/Utils/Validation";
import DefaultInput from "../../config/DefaultInput";
import { CountryDetails } from "../../config/CountryList";

const Signup = () => {
  const ref = window.location.href.split("=")[1];

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCpassword] = useState("");
  const [invitation, setInvitation] = useState("");
  const [otp, setOtp] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [reEnterPasswordType, setReEnterPasswordType] = useState("password");
  const [disableBtn, setDisableBtn] = useState(false);
  const [validations, setValidations] = useState(false);
  const [numberValidation, setNumberValidation] = useState(false);
  const [specialValidation, setSpecialValidation] = useState(false);
  const [minLength, setMinLength] = useState(false);
  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [isShow, setIsShow] = useState("");
  const [countryCode, setCountryCode] = useState("91");
  const [countryName, setCountryName] = useState("India");
  const [error, setError] = useState('');


  const handleRegister = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.signup(name, email, otp, countryCode, countryName, mobileNumber, password, cPassword, invitation).then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          alertSuccessMessage(result.message);
          navigate("/login");
          setIsShow(false);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  useEffect(() => {
    password && /(?=.*?[0-9])/.test(password)
      ? setNumberValidation(true)
      : setNumberValidation(false);
  }, [password]);

  useEffect(() => {
    password && /(?=.*?[#?!@$%^&*-])/.test(password)
      ? setSpecialValidation(true)
      : setSpecialValidation(false);
  }, [password]);

  useEffect(() => {
    password && /.{8,}/.test(password)
      ? setMinLength(true)
      : setMinLength(false);
  }, [password]);

  useEffect(() => {
    const getReferralCodeFromUrl = () => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get('reffcode') || '';
    };

    const code = getReferralCodeFromUrl();
    setInvitation(code);
  }, []);

  const handleGetOtp = async (email) => {
    if (!email) {
      alertWarningMessage("Please enter email");
      return;
    }
    LoaderHelper.loaderStatus(true);
    await AuthService.sendOtp(email, "registration").then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        alertSuccessMessage(result?.message);
        setDisableBtn(true);
      }
    });
  };

  const togglePassword = () => {
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  const toggleConfirmPassword = () => {
    setReEnterPasswordType(reEnterPasswordType === "password" ? "text" : "password");
  };

  const errorMessage = matchPassword(password, cPassword);


  const handleChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setMobileNumber(value);
      setError('');
    } else {
      setError('Mobile number must be exactly 10 digits.');
    }
  };

  const handleChangeotp = (e) => {
    const value = e.target.value;
    if (/^\d{0,6}$/.test(value)) {
      setOtp(value);
      setError('');
    } else {
      setError('OTP must be exactly 6 digits.');
    }
  };

  const handleSubmit = (e) => {
    if (!isChecked) {
      e.preventDefault();
      setShowMessage(true);
    } else {
      setShowMessage(false);
    }
  };

  return (
    <>
      <Header />
      <div className="hero inner_page">
        <figure className="heroTopShap">
          <img src="assets/img/heroTop-shap.svg" alt="" />
        </figure>
        <div className="container">
          <div className="align-items-start justify-content-center row">
            <div className="col-lg-6 col-12 mx-auto">
              <form
                className="custom-form form_card"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleRegister();
                }}
              >
                <div className="card-body p-0">
                  <div className="section-title text-center mb-4 mb-md-5">
                    <h3 className="text-gradient">
                      <span>CREATE ACCOUNT!</span>
                    </h3>
                    <p>Enter details to create a new account.</p>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="form-group">
                        <input
                          type="text"
                          name="name"
                          id="form-signId"
                          className="form-control email_input"
                          placeholder="Enter Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          id="form-signId"
                          className="form-control email_input"
                          placeholder="Enter Email Address"
                          value={email}
                          onChange={(e) => setEmail(e.target.value.toLowerCase())}
                          required
                        />
                        <i className="ri-mail-line"></i>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="form-group">
                        <input
                          type="text"
                          name="otp"
                          id="form-signId"
                          className="form-control email_input"
                          placeholder="Enter OTP"
                          value={otp}
                          onChange={handleChangeotp}
                          maxLength={6}
                          required
                        />
                        <button
                          type="button"
                          className="inlineBtn_fill btn-sm"
                          onClick={() => {
                            validateEmail(email) !== undefined && notEqualsZero(email)
                              ? alertErrorMessage("Invalid emailId")
                              : handleGetOtp(email);
                          }}
                        >
                          <span>{disableBtn ? "Resend OTP" : "GET OTP"}</span>
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="row gx-2">
                        <div className="col-lg-4 col-md-4 col-4">
                          <div className="field-box">
                            <label htmlFor="password" className="form-label">
                              Code <span style={{ color: "red" }}> *</span>
                            </label>
                            <select
                              name="countryCode"
                              id="countryCode"
                              value={`${countryCode}|${countryName}`}
                              onChange={(e) => {
                                const [code, name] = e.target.value.split("|");
                                setCountryCode(code);
                                setCountryName(name);
                              }}
                              className="form-control"
                              style={{ background: "#272f27" }}
                              required
                            >
                              {CountryDetails?.map((value) => (
                                <option value={`${value.phone}|${value.label}`} key={value?.phone}>
                                  {`${value?.label} (+${value?.phone})`}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-8 col-8">
                          <div className="form-group">
                            <label htmlFor="password" className="form-label">
                              Mobile Number<span style={{ color: "red" }}> *</span>
                            </label>
                            <input
                              type="text"
                              name="number"
                              id="form-signId"
                              className="form-control email_input"
                              placeholder="Enter Mobile Number"
                              value={mobileNumber}
                              onChange={handleChange}
                              maxLength={10}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="form-group">
                        <label htmlFor="password" className="form-label">
                          Password<span style={{ color: "red" }}> *</span>
                        </label>
                        <div className="field-otp-box" style={{ position: "relative" }}>
                          <DefaultInput
                            type={passwordType}
                            value={password}
                            placeholder="Enter password"
                            name="form-password"
                            id="form-password"
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                          {passwordType === "password" ? (
                            <i className="ri-lock-line position-absolute" />
                          ) : (
                            <i className="ri-lock-unlock-line position-absolute" />
                          )}
                          <Link
                            to="#"
                            className="show_password opt_btn btn-sm"
                            onClick={togglePassword}
                            style={{ cursor: "pointer" }}
                          ></Link>
                          {validations && (
                            <div
                              className="password-tooltip"
                              style={{
                                position: "absolute",
                                top: "100%",
                                left: 0,
                                backgroundColor: "rgb(39, 47, 39)",
                                color: "white",
                                padding: "10px",
                                zIndex: 1,
                                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                                fontSize: "small",
                              }}
                            >
                              <div>
                                {numberValidation ? (
                                  <div className="d-flex align-items-center">
                                    <i className="ri-check-double-line text-success"></i>
                                    <span>At least 1 number</span>
                                  </div>
                                ) : (
                                  <div className="d-flex align-items-center">
                                    <i className="ri-close-fill text-danger"></i>
                                    <span>At least 1 number</span>
                                  </div>
                                )}
                              </div>
                              <div>
                                {specialValidation ? (
                                  <div className="d-flex align-items-center">
                                    <i className="ri-check-double-line text-success"></i>
                                    <span>At least 1 special character</span>
                                  </div>
                                ) : (
                                  <div className="d-flex align-items-center">
                                    <i className="ri-close-fill text-danger"></i>
                                    <span>At least 1 special character</span>
                                  </div>
                                )}
                              </div>
                              <div>
                                {minLength ? (
                                  <div className="d-flex align-items-center">
                                    <i className="ri-check-double-line text-success"></i>
                                    <span>Minimum 8 characters</span>
                                  </div>
                                ) : (
                                  <div className="d-flex align-items-center">
                                    <i className="ri-close-fill text-danger"></i>
                                    <span>Minimum 8 characters</span>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="form-group">
                        <label htmlFor="cPassword" className="form-label">
                          Confirm Password
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="field-otp-box">
                          <DefaultInput
                            type={reEnterPasswordType}
                            value={cPassword}
                            placeholder="Enter Confirm password"
                            name="form-password"
                            id="form-password"
                            className="form-control"
                            autoComplete="off"
                            errorstatus={
                              notEqualsZero(cPassword) && matchPassword(password, cPassword) ? "true" : "false"
                            }
                            onChange={(e) => setCpassword(e.target.value)}
                            required
                          />
                          <i
                            className={
                              reEnterPasswordType === "password" ? "ri-lock-line" : "ri-lock-unlock-line"
                            }
                            onClick={toggleConfirmPassword}
                          />
                        </div>
                        {errorMessage && <div className="error-message">{errorMessage}</div>}

                        <Link
                          to="#"
                          className="show_password opt_btn btn-sm"
                          onClick={(e) => toggleConfirmPassword()}
                        ></Link>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="form-group">
                        <label htmlFor="password" className="form-label">
                          Referral code (Optional)
                        </label>
                        <input id="Invitation" name="mInvitation" placeholder="Enter Invitation Code" className="form-control"
                          autoComplete="off" value={invitation} onChange={(e) => setInvitation(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-check mb-3">
                        <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={isChecked} onChange={() =>
                          setIsChecked((prev) => !prev)
                        }
                        />
                        I agree to accept&nbsp;
                        <Link to="/TermsOfUsePage" className="btn-link" target="_blank">Terms of Use <span style={{ color: "red" }}>*</span>
                        </Link>
                        {!isChecked && showMessage ? (
                          <div style={{ color: "red", fontWeight: "bold" }}>
                            Please accept Term of Use by clicking on checkbox
                          </div>
                        ) : (
                          " "
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 mb-3 mt-3">
                      <div className="field-box">
                        <button className="col-lg-12 col-md-10 col-12 mx-auto mt-4 inlineBtn_fill w-100 btn-block" type="submit" onClick={handleSubmit}>
                          <span>Sign Up</span>
                        </button>
                        <div className="col-lg-12 col-md-12 col-12 text-center mt-4">
                          <p>Already have an account?</p>
                          <Link className="fw-bold" to="/login">
                            Back to Sign In
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Signup;
