import { Link, useNavigate } from "react-router-dom";
import Footer from "../Footer";
import Header from "../Header";
import { useState } from "react";
import AuthService from "../../api/services/AuthService";
import LoaderHelper from "../../config/Utils/Loading/LoaderHelper";
import {
  alertErrorMessage,
  alertSuccessMessage,
} from "../../config/Utils/CustomAlertMessage";

const Login = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const LoginHandler = async (e) => {
    e.preventDefault();
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.login(email, password);
      if (result?.success) {
        LoaderHelper.loaderStatus(false);
        alertSuccessMessage(result?.message || "Login successful");
        sessionStorage.setItem("token", result?.token);
        sessionStorage.setItem("userId", result?.data?._id);
        navigate("/account/dashboard");
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result?.message);
      }
    } catch (error) {
      alertErrorMessage(error?.message);
    }
  };

  return (
    <>
      <Header />
      <div className="hero inner_page">
        <figure className="heroTopShap">
          <img src="assets/img/heroTop-shap.svg" alt="" />
        </figure>
        <div className="container">
          <div className="align-items-start justify-content-center row">
            <div className="col-lg-6 col-12 mx-auto">
              <form
                className="custom-form form_card"
                onSubmit={(e) => LoginHandler(e)}
              >
                <div className="card-body p-0">
                  <div className="section-title text-center mb-4 mb-md-5">
                    <h3 className=" text-gradient">
                      <span>Welcome Back!</span>
                    </h3>
                    <p>Please Enter login Details to Continue</p>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="form-group">
                        <input
                          type="email"
                          name="form-phone"
                          id="form-signId"
                          className="form-control email_input"
                          placeholder="Enter Email Address"
                          value={email}
                          required
                          onChange={(e) => {
                            e.stopPropagation();
                            setEmail(e.target.value.toLocaleLowerCase());
                          }}
                        />
                        <i className="ri-mail-line"></i>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="form-group">
                        <input
                          type={showPassword ? "text" : "password"}
                          name="form-password"
                          id="form-password"
                          className="form-control"
                          placeholder="Password"
                          value={password}
                          required
                          onChange={(e) => {
                            e.stopPropagation();
                            setPassword(e.target.value);
                          }}
                        />
                        {showPassword ? (
                          <i
                            className="ri-lock-unlock-line "
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowPassword(false);
                            }}
                          ></i>
                        ) : (
                          <i
                            className="ri-lock-line "
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowPassword(true);
                            }}
                          ></i>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-12 text-end mb-5">
                      <Link className="" to="/forgotPassword">
                        {" "}
                        Forgot Password?{" "}
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-10 col-12 mx-auto">
                    <button
                      type="submit"
                      className="inlineBtn_fill  w-100 btn-block "
                    >
                      SIGN IN
                    </button>
                  </div>
                  <div className="col-lg-12 col-md-12 col-12 text-center mt-4">
                    <p>Do you have an account?</p>{" "}
                    <Link className="fw-bold" to="/signup">
                      {" "}
                      Create New Account{" "}
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Login;
