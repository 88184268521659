import { Link } from "react-router-dom";
import userThumb from "../../config/img/user_thumb.png";
import { useEffect, useState } from "react";
import AuthService from "../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../config/Utils/CustomAlertMessage";
import { Endpoints } from "../../api/apiConfig/Endpoints";

const EditProfile = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [dob, setDob] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [profileData, setProfileData] = useState("");
    const [kycData, setKycData] = useState("");
    const [localImage, setLocalImage] = useState(null);
    const [profileImage, setProfileImage] = useState(null);
    const [countryCode, setCountryCode] = useState("+91");
    const [countryName, setCountryName] = useState("");

    useEffect(() => {
        return () => {
            if (localImage) {
                URL.revokeObjectURL(localImage);
            }
        };
    }, [localImage]);

    const handleImageChange = (e) => {
        const fileUploaded = e.target.files[0];
        if (fileUploaded) {
            const objectUrl = URL.createObjectURL(fileUploaded);
            setLocalImage(objectUrl);
            setProfileImage(fileUploaded);
        } else {
            setLocalImage(null);
            setProfileImage(null);
        }
    };

    const updateProfileDetails = async () => {
        const formData = new FormData();
        formData.append("profileImage", profileImage);
        formData.append("fullName", name);
        formData.append("email", email);
        formData.append("mobile_number", String(mobileNumber));
        await AuthService.updateProfile(formData).then(async (result) => {
            if (result?.success) {
                try {
                    alertSuccessMessage(result?.message);
                    handleProfileData();
                    window.location.reload();
                } catch {
                    alertErrorMessage(result?.message);
                }
            } else {
                alertErrorMessage(result?.message);
            }
        });
    };

    const InputHandler = (e) => {
        e.stopPropagation();
        switch (e.target.name) {
            case "userName":
                setName(e.target.value);
                break;
            case "emailId":
                setEmail(e.target.value);
                break;
            case "mobileNumber":
                setMobileNumber(e.target.value);
                break;
            default:
                break;
        }
    };

    const handleProfileData = async () => {
        const result = await AuthService.getProfileData();
        if (result.success) {
            try {
                setProfileData(result?.data);
                setKycData(result?.data?.kyc_id);
                setName(result?.data?.fullName);
                setEmail(result?.data?.email);
                setMobileNumber(result?.data?.mobile_number.replace(result?.data?.country_code, ''));
                setDob(result?.data?.dob);
                setLocalImage(result?.data?.profileImage);
                setCountryCode(result?.data?.country_code || "+91");
                setCountryName(result?.data?.country);
            } catch (error) {
                alertErrorMessage(result?.message);
            }
        } else {
            alertErrorMessage(result.message);
        }
    };

    useEffect(() => {
        handleProfileData();
    }, []);


    return (
        <>
            <div className="wrap">
                <div className="content container-fluid">
                    <div className="row g-4 justify-content-center">
                        <div className="col-lg-12">
                            <div className="block-head block-head-sm">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="block-head-content">
                                        <h3 className="text-white mb-0">
                                            <i className="ri-user-line me-3"></i>
                                            Edit Profile
                                        </h3>
                                    </div>
                                    <div className="block-head-content d-none d-xl-block">
                                        <div className="container-fluid py-2">
                                            <nav aria-label="breadcrumb">
                                                <ol className="breadcrumb py-2 px-0">
                                                    <li className="breadcrumb-item"><Link to="/account/dashboard">Home</Link></li>
                                                    <li className="breadcrumb-item active" aria-current="page">Account</li>
                                                    <li className="breadcrumb-item active" aria-current="page">My Profile</li>
                                                </ol>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xxl-6 col-lg-8 m-auto">
                            <div className="card h-100">
                                <div className="card-body">
                                    <form>
                                        <div className="col-md-12">
                                            <div className="img-prev">
                                                <div className="avatar-preview">
                                                    <img
                                                        src={localImage?.localImage ? Endpoints.baseurl + profileData?.profileImage : userThumb} className="img-fluid" alt="Profile" />
                                                </div>
                                                <div className="avatar-edit">
                                                    <input type="file" id="imageUpload" name="image" onChange={handleImageChange} />
                                                    <label htmlFor="imageUpload"><i className="ri-upload-cloud-2-line me-2"></i>Upload Photo</label>
                                                    <small>Click button to upload photo</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="exampleInputName">Name</label>
                                            <input className="form-control" id="exampleInputName" type="text" name="userName" value={name} onChange={InputHandler} disabled />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="exampleInputEmail1">Email ID</label>
                                            <input className="form-control" id="exampleInputEmail1" type="email" name="emailId" value={email} onChange={InputHandler} disabled />
                                        </div>
                                        <div className="row d-flex">
                                            <div className="mb-3 col-3">
                                                <label className="form-label" htmlFor="mobileNumber">Code</label>
                                                <div className="d-flex">
                                                    <input className="form-control" id="mobileNumber" type="text" name="mobileNumber" value={countryName + " +" + countryCode}
                                                        onChange={InputHandler} disabled />
                                                </div>
                                            </div>
                                            <div className="mb-3 col-9">
                                                <label className="form-label" htmlFor="mobileNumber">Mobile Number</label>
                                                <div className="d-flex">
                                                    <input className="form-control" id="mobileNumber" type="text" name="mobileNumber" value={mobileNumber}
                                                        onChange={InputHandler} maxLength={10} />
                                                </div>
                                            </div>
                                        </div>


                                        {profileData.kycVerified === 2 ? (
                                            <Link className="btn btn_gradient btn-block w-100 mt-3" to="/account/myProfile" onClick={updateProfileDetails}>
                                                Update Profile
                                            </Link>
                                        ) : (
                                            <div className="text-danger" style={{ textAlign: 'center', color: 'red', marginTop: '1rem', fontWeight: 'bold', fontSize: '20px' }}>
                                                Please Complete KYC to Update Profile
                                            </div>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
};

export default EditProfile;
