// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.loader2 {
	background-color: #1111114d;
	overflow: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0; left: 0;
  display: flex;
  align-items: start;
  align-content: center; 
  justify-content: flex-start;  
	z-index: 100000;
}
 

 


.an_animation{
  position: absolute;
  top: 0;   
  left: 0;
  width: 100%;  
  height: 5px; 
   overflow: hidden;
}

.an_animation span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f0b90b;
  display: inline-block;
  animation: animate 10s infinite linear;
}

@keyframes animate{
   0%{
      transform: translateX(-100%);
   }
   50%{
       transform: translateX(100%);
    }
   100%{
       transform: translateX(-100%);
    }
}


`, "",{"version":3,"sources":["webpack://./src/config/Utils/Loading/Loading.css"],"names":[],"mappings":";;AAEA;CACC,2BAA2B;CAC3B,gBAAgB;EACf,WAAW;EACX,YAAY;EACZ,eAAe;EACf,MAAM,EAAE,OAAO;EACf,aAAa;EACb,kBAAkB;EAClB,qBAAqB;EACrB,2BAA2B;CAC5B,eAAe;AAChB;;;;;;AAMA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,WAAW;GACV,gBAAgB;AACnB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,qBAAqB;EACrB,sCAAsC;AACxC;;AAEA;GACG;MACG,4BAA4B;GAC/B;GACA;OACI,2BAA2B;IAC9B;GACD;OACI,4BAA4B;IAC/B;AACJ","sourcesContent":["\r\n\r\n.loader2 {\r\n\tbackground-color: #1111114d;\r\n\toverflow: hidden;\r\n  width: 100%;\r\n  height: 100%;\r\n  position: fixed;\r\n  top: 0; left: 0;\r\n  display: flex;\r\n  align-items: start;\r\n  align-content: center; \r\n  justify-content: flex-start;  \r\n\tz-index: 100000;\r\n}\r\n \r\n\r\n \r\n\r\n\r\n.an_animation{\r\n  position: absolute;\r\n  top: 0;   \r\n  left: 0;\r\n  width: 100%;  \r\n  height: 5px; \r\n   overflow: hidden;\r\n}\r\n\r\n.an_animation span {\r\n  position: absolute;\r\n  top: 0;\r\n  left: 0;\r\n  width: 100%;\r\n  height: 100%;\r\n  background: #f0b90b;\r\n  display: inline-block;\r\n  animation: animate 10s infinite linear;\r\n}\r\n\r\n@keyframes animate{\r\n   0%{\r\n      transform: translateX(-100%);\r\n   }\r\n   50%{\r\n       transform: translateX(100%);\r\n    }\r\n   100%{\r\n       transform: translateX(-100%);\r\n    }\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
