import React, { useEffect, useState } from 'react';
import { alertErrorMessage, alertSuccessMessage } from '../../config/Utils/CustomAlertMessage';
import AuthService from '../../api/services/AuthService';
import LoaderHelper from '../../config/Utils/Loading/LoaderHelper';
import { Link } from 'react-router-dom';


const HelpCenter = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [images, setImages] = useState([]);
  const [data, setData] = useState([]);
  const [profileData, setProfileData] = useState("");
  const [kycData, setKycData] = useState("");
  const [userId, setUserId] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'mobileNumber':
        setMobileNumber(value);
        break;
      case 'title':
        setTitle(value);
        break;
      case 'description':
        setDescription(value);
        break;
      default:
        break;
    }
  };

  const handleFileChange = (event) => {
    setImages(Array.from(event.target.files));
  };

  const handleCreateTicket = async () => {
    LoaderHelper.loaderStatus(true);
    const formData = new FormData();
    formData.append('title', title);
    formData.append('name', name);
    formData.append('email', email);
    formData.append('mobile_number', mobileNumber);
    formData.append('description', description);
    images.forEach((image, index) => {
      formData.append(`images`, image);
    });
    try {
      LoaderHelper.loaderStatus(false);
      const result = await AuthService.createTicket(formData);
      if (result?.success) {
        LoaderHelper.loaderStatus(false);
        alertSuccessMessage(result?.message);
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result?.message);
      }
    } catch (error) {
      LoaderHelper.loaderStatus(false);
      alertErrorMessage(error.message);
    }
  };

  const handleProfileData = async () => {
    const result = await AuthService.getProfileData()
    if (result.success) {
      try {
        setProfileData(result?.data);
        setKycData(result?.data?.kyc_id)
        setName(result?.data?.firstName + " " + result?.data?.lastName)
        setEmail(result?.data?.email)
        setUserId(result?.data?._id)
      } catch (error) {
        alertErrorMessage(result?.message)
      }
    }
    else {
      alertErrorMessage(result.message)
    }
  }

  useEffect(() => {
    handleProfileData();
  }, [])


  const handleSeenTicket = async (userId) => {
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.seenTicket(userId);
      if (result?.success) {
        LoaderHelper.loaderStatus(false);
        alertSuccessMessage(result?.message);
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result?.message);
      }
    } catch (error) {
      LoaderHelper.loaderStatus(false);
      alertErrorMessage(error.message);
    }
  }

  return (
    <div className="wrap">
      <div className="content container-fluid">
        <div className="container-xl px-4 mt-n10">
          <div className="card mb-4">
            <div className="d-flex justify-content-between align-items-center p-4">
              <div style={{ fontSize: '30px' }}>
                <strong>Help Center</strong>
              </div>
              <Link to="/account/MyTicketList" className="btn btn-warning btn-block m-0" onClick={() => handleSeenTicket(userId)}>
                View My Ticket List
              </Link>
            </div>
            <div className="card-body">
              <form>
                <div className="row g-4">
                  <div className="col-md-6">
                    <label className="small mb-1" htmlFor="inputFirstName">Your Name <span style={{ color: "red" }}>*</span></label>
                    <input type="text" className="form-control form-control-solid m-0" id="inputFirstName" placeholder="Enter your name" name="name" value={name} onChange={handleInputChange} disabled />
                  </div>
                  <div className="col-md-6">
                    <label className="small mb-1" htmlFor="inputEmail">Email Address <span style={{ color: "red" }}>*</span></label>
                    <input className="form-control form-control-solid m-0" id="inputEmail" type="text" placeholder="Enter your Email Address" name="email" value={email} onChange={handleInputChange} disabled />
                  </div>
                  <div className="col-md-6">
                    <label className="small mb-1" htmlFor="inputTitle">Subject <span style={{ color: "red" }}>*</span></label>
                    <input className="form-control form-control-solid m-0" id="inputTitle" type="text" placeholder="Enter Your Subject" name="title" value={title} onChange={handleInputChange} />
                  </div>
                  <div className="col-md-6">
                    <label className="small mb-1" htmlFor="inputPhoneNumber">Phone Number</label>
                    <input className="form-control form-control-solid m-0" id="inputPhoneNumber" type="text" placeholder="Enter Your Phone Number" name="mobileNumber" value={mobileNumber} onChange={handleInputChange}
                      maxLength={10} />
                  </div>
                  <div className="col-md-6">
                    <label className="small mb-1" htmlFor="inputDescription">Description</label>
                    <textarea className="form-control" rows="8" spellCheck="false" name="description" value={description} onChange={handleInputChange}></textarea>
                  </div>
                  <div className="col-md-6">
                    <div className="mt-2 mb-3">
                      <h6 className="title mb-1">Upload Your Snap Shots</h6>
                    </div>
                    <div className="upload-area upload_xl">
                      <div className="brows-file-wrapper">
                        <input name="file" rows="8" id="file1" type="file" className="inputfile" multiple accept="image/*" onChange={handleFileChange} />
                        {images.length === 0 ?
                          <label htmlFor="file1" title="No File Choosen">
                            <i className="ri-upload-cloud-line"></i>
                            <span className="text-center">Choose a File</span>
                            <span className="file-type text-center mt--10">Drag or choose your file to upload</span>
                          </label>
                          :
                          <label htmlFor="file1" title="No File Choosen">
                            <i className="text-success ri-check-double-fill"></i>
                            <span className="text-center mb-2">File Uploaded</span>
                            <span className="file-type text-center mt--10">Files Selected: {images.length}</span>
                          </label>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <button className="btn btn-warning btn-block w-100 m-0" type="button" onClick={handleCreateTicket} disabled={!name || !email || !title || !mobileNumber || !description || !images}>Create Ticket</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {data.length > 0 && (
            <div className="card mt-4">
              <div className="card-header">
                <strong>My Tickets</strong>
              </div>
              <div className="card-body">
                <ul>
                  {data.map((ticket, index) => (
                    <li key={index}>{ticket.title}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </div >
  );
};

export default HelpCenter;
